var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-slider"},[_c('div',{staticClass:"left-slide"},[_c('div',{staticClass:"left-slide__bg",class:{ scale:_vm.leftZoom }}),_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
            el: '#services',
            duration: '1500',
            easing: 'ease-out',
            offset: -150,
            }),expression:"{\n            el: '#services',\n            duration: '1500',\n            easing: 'ease-out',\n            offset: -150,\n            }"}],attrs:{"href":"#"},on:{"mouseover":function($event){_vm.leftZoom=!_vm.leftZoom},"mouseleave":function($event){_vm.leftZoom=!_vm.leftZoom}}},[_c('img',{attrs:{"src":"/img/bg2.png","alt":""}})])]),_c('div',{staticClass:"right-slide"},[_c('div',{staticClass:"right-slide__bg",class:{ scale: _vm.rightZoom }}),_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
            el: '#it-solutions',
            duration: '1500',
            easing: 'ease-out',
            offset: -150,
            }),expression:"{\n            el: '#it-solutions',\n            duration: '1500',\n            easing: 'ease-out',\n            offset: -150,\n            }"}],attrs:{"href":"#"},on:{"mouseover":function($event){_vm.rightZoom=!_vm.rightZoom},"mouseleave":function($event){_vm.rightZoom=!_vm.rightZoom}}},[_c('img',{attrs:{"src":"/img/bg3.png","alt":""}})])]),_c('div',{staticClass:"top-slider__mobile"},[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
            el: '#services',
            duration: '1500',
            easing: 'ease-out',
            offset: -150,
            }),expression:"{\n            el: '#services',\n            duration: '1500',\n            easing: 'ease-out',\n            offset: -150,\n            }"}],staticClass:"top-link",attrs:{"href":"#"}},[_c('img',{attrs:{"src":"/img/bg2.png","alt":""}})]),_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
            el: '#it-solutions',
            duration: '1500',
            easing: 'ease-out',
            offset: -150,
            }),expression:"{\n            el: '#it-solutions',\n            duration: '1500',\n            easing: 'ease-out',\n            offset: -150,\n            }"}],staticClass:"bottom-link",attrs:{"href":"#"}},[_c('img',{attrs:{"src":"/img/bg3.png","alt":""}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }