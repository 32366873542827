<template>
  <div class="direction">
    <div class="direction__title">
      <div class="container text-center">
        <h2 class="section-title">Какое направление вас интересует?</h2>
      </div>
    </div>
    <div class="direction__accordion">
        <Accordion></Accordion>
    </div>
  </div>
</template>

<script>

// import Accordion from "@/pages/Home/LocationAccordion/Accordion";

import Accordion from "@/pages/Home/Accordion/Accordion";

export default {
  components: {
    Accordion
  }
}
</script>

<style lang="scss" scoped>
.direction {
  margin: 70px 0;
  .direction__title {
    margin-bottom: 50px;
  }
}
</style>
