<template>
    <div class="manager-system">
        <div class="manager-system__desc">
            <div class="manager-system__left">
                <img class="manager-system__img" src="/img/Robotics_Process_Automation.svg" alt="">
            </div>
            <div class="manager-system__righ">
                <p>
                    Рутина снижает мотивацию персонала и уменьшает производительность труда. Долой рутину! Компания Комплит ИС предлагает автоматизировать повторяющиеся типовые задачи за счет внедрения средств роботизации процессов.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Robotics"
    }
</script>

<style scoped lang="scss">
    .manager-system {
        margin: 2rem 0;

        .manager-system__desc {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-evenly;

            .manager-system__left {
                max-width: calc(100% / 12 * 6);
                padding: 0 15px;

                .manager-system__img {
                    max-width: 100%;
                    width: 600px;
                    height: auto;
                }
            }

            .manager-system__righ {
                max-width: calc(100% / 12 * 6);
                padding: 0 15px;
            }

            @media screen and (max-width: 867px) {
                .manager-system__left {
                    max-width: 100%;
                    margin: 10px 0;
                }

                .manager-system__righ {
                    max-width: 100%;
                    margin: 10px 0;

                    @media screen and (max-width: 320px) {
                        font-size: 0.8em;
                    }
                }
            }
        }

        .manager-system__text {
            text-align: center;
            margin: 2rem 0;

            @media screen and (max-width: 768px) {
                margin: 0;
            }
        }
    }
</style>