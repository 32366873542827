<template>
    <div class="inner-list">
        <p>Внедрение процесса управлением финансовыми ресурсами, связанными с ИТ. Подразумевает создание системы учета доходов и затрат, внедрение бюджетного управления ИТ, анализ путей оптимизации затрат и увеличения доходов, создание сквозной системы финансовой отчетности и автоматизация формирования отчетности.</p>
    </div>
</template>

<script>
    export default {
        name: "ItFinance"
    }
</script>

<style scoped>

</style>