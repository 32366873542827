<template>
  <div class="it-solutions" id="it-solutions">

    <div class="it-solutions__title">
      <div class="it-solutions__title_left"></div>
      <div class="it-solutions__title_center">
        <h2>
          ИТ-решения для операторов связи
          и телекоммуникационных компаний
        </h2>
      </div>
    </div>

    <div class="container it-solutions-solutions-bss">

      <div class="it-solutions-solutions-bss_left">
        <img src="/img/it-solutions.svg" alt="">
      </div>

      <div class="it-solutions-solutions-bss_right">
        <h3 class="it-solutions-solutions-bss_title">РЕШЕНИЯ BSS/OSS</h3>
        <p>
          Своевременный вывод актуальных и качественных услуг на рынок, бесперебойная работа биллинговых систем и
          абонентских служб – все это зависит от налаженной и устойчивой работы внутренних бизнес-процессов, сетевых
          ресурсов, информационных систем. Именно поэтому решения класса OSS/BSS неизменно востребованы в
          телекоммуникационных компаниях.
        </p>
        <p>
          Комплит ИС готова предоставить инструменты и решения, которые помогут оператору создать комфортную среду для
          абонента, усилить его привязанность, обеспечивая высокую лояльность в будущем.
        </p>
      </div>

    </div>
    <!-- /. it-solutions-solutions-bss -->

    <div class="container it-solutions-subsystems main-wrap">

      <!--   OSS   -->

      <div class="it-solutions-subsystems__title">
        <h3>Ключевые подсистемы oss</h3>
      </div>
      <!-- ./it-solutions-subsystems__title -->

      <p class="it-solutions-subsystems__paragraph">
        <strong>OSS (Operations Support Systems)</strong> &mdash; системы поддержки деятельности операторов, обеспечивают интеграцию следующих
        элементов:
      </p>

      <div class="it-solutions-subsystems__list">
        <div class="it-solutions-subsystems__list_img">
          <img src="/img/nri.png" alt="">
        </div>
        <p>
          <b>Network Resource Inventory (NRI)</b> &mdash; подсистема инвентаризации и технического учета сетевых ресурсов.
          Учитывает
          и отражает физический уровень сети, логический уровень сети, позволяет детализировать трафик с точностью до
          виртуального канала, временного интервала или информационного потока той или иной услуги;
        </p>
      </div>

      <div class="it-solutions-subsystems__list">
        <div class="it-solutions-subsystems__list_img">
          <img src="/img/ifm.png" alt="">
        </div>
        <p>
          <b>Intelligent Fault Management (IFM)</b> &mdash; экспертная система анализа и фильтрации аварийных сообщений.
          Подсистема выявляет причины сбоев в узлах сети, обеспечивает фильтрацию вторичных аварийных сообщений,
          позволяет своевременно фиксировать деградацию качества каналов, влияющую на качество услуги;
        </p>
      </div>

      <div class="it-solutions-subsystems__list">
        <div class="it-solutions-subsystems__list_img">
          <img src="/img/slam.png" alt="">
        </div>
        <p>
          <b>Service Level Agreement Management (SLAM)</b> &mdash; автоматизированная система мониторинга контролируемых
          параметров, гарантирующих качество обслуживания абонентов. Играет важную роль в детализации процессов
          предоставления услуг, определяющих взаиморасчеты с абонентом;

        </p>
      </div>

      <div class="it-solutions-subsystems__list">
        <div class="it-solutions-subsystems__list_img">
          <img src="/img/pmhd.png" alt="">
        </div>
        <p>
          <b>Problem Management + Help Desk (PMHD)</b> &mdash; автоматизированная система формирования и сопровождения заказов
          на проведение ремонтных и регламентных работ на сети оператора. Позволяет минимизировать простои за счет
          автоматической генерации заказов при локализации аварийной ситуации, а также сокращения издержек, связанных со
          взаимодействием различных служб технической поддержки сети, и прогнозирования возможных отказов в системе IFM
          и генерации нарядов на плановые регламентные работы, предотвращающие возможные отказы;

        </p>
      </div>

      <div class="it-solutions-subsystems__list">
        <div class="it-solutions-subsystems__list_img">
          <img src="/img/pms.png" alt="">
        </div>
        <p>
          <b>Performance Management System (PMS)</b> &mdash; автоматизированная система анализа баланса нагрузки в сети,
          предназначенная для оптимизации сетевой производительности. Дает возможность своевременно прогнозировать
          перегрузки в узлах сети, планировать внедрение новых услуг, необходимую для этого модернизацию сети и
          приводить их в соответствие с маркетинговым планом;

        </p>
      </div>

      <div class="it-solutions-subsystems__list">
        <div class="it-solutions-subsystems__list_img">
          <img src="/img/odm.png" alt="">
        </div>
        <p>
          <b>Order Management (ODM)</b> &mdash; автоматизированная система работы с абонентами, которая принимает и формирует
          заявки на активацию сервисов, согласует их с техническими возможностями имеющейся инфраструктуры и программой
          развития сети, фиксирует рекламации, планирует и сопровождает их устранение;

        </p>
      </div>

      <div class="it-solutions-subsystems__list">
        <div class="it-solutions-subsystems__list_img">
          <img src="/img/wfm.png" alt="">
        </div>
        <p>
          <b>Working Force Management (WFM)</b> &mdash; автоматизированная система управления персоналом. Координирует
          техническую переподготовку сотрудников в соответствии с программой развития сети, планирует график работы
          персонала.

        </p>
      </div>

      <!--   BSS   -->

      <div class="container it-solutions-subsystems__title">
        <h3>Ключевые подсистемы bss</h3>
      </div>
      <!-- ./it-solutions-subsystems__title -->

      <p class="it-solutions-subsystems__paragraph">
        <strong>BSS (Business Support Systems)</strong> — системы поддержки бизнеса, которые включают в себя
        такие элементы, как:
      </p>

      <div class="it-solutions-subsystems__list bss">
        <div class="it-solutions-subsystems__list_img">
          <img src="/img/ssss.png" alt="">
        </div>
        <p>
          <b>Subscriber Service Support System (SSSS)</b> &mdash; подсистема абонентского обслуживания, которая объединяет
          работу контакт-центра, обрабатывающего обращения абонентов, CRM-системы, фиксирующей историю взаимодействий с
          абонентами, и системы самообслуживания абонента (справочная информация, подписка и т. п.);
        </p>
      </div>

      <div class="it-solutions-subsystems__list bss">
        <div class="it-solutions-subsystems__list_img">
          <img src="/img/bs.png" alt="">
        </div>
        <p>
          <b>Billing System (BS)</b> &mdash; автоматизированная система взаиморасчетов с абонентами и детализации
          предоставленных услуг. Она выставляет счета абонентам оператора, контролирует своевременность оплаты и
          позволяет абоненту получить детализацию с точностью до отдельной транзакции и сценария ее реализации;
        </p>
      </div>

      <div class="it-solutions-subsystems__list bss">
        <div class="it-solutions-subsystems__list_img">
          <img src="/img/erp.png" alt="">
        </div>
        <p>
          <b>Enterprise Resource Planning (ERP)</b> &mdash; набор интегрированных приложений, которые поддерживают основные
          аспекты управленческой деятельности оператора: планирование ресурсов, необходимых для предоставления услуг,
          оперативное управление выполнением планов, учет и анализ результатов деятельности. Среди основных требований,
          предъявляемых к ERP-системам, &mdash; централизация базы данных, работа в режиме онлайн, поддержка территориально
          распределенных структур, возможность использования различных СУБД на ряде аппаратно-программных платформ.
        </p>
      </div>


      <!--   ADVANTAGES   -->

      <div class="container it-solutions-advantages-title">
        <h3>Преимущества</h3>
      </div>

      <div class="it-solutions__advantages">
        <div>
          <span class="it-solutions__advantages_num">
            1
          </span>
        </div>
        <p class="it-solutions__advantages_text">
          <b>Внедрение комплекса OSS/BSS</b> открывает перед оператором новые возможности, которые становятся доступными
          с
          умной конвергентной системой биллинга, позволяющей быстро реагировать на изменения рынка, предоставлять
          абонентам любые услуги связи в любой сети, тарифицировать услуги в режиме online и offline, делать целевые
          индивидуальные предложения абонентам, заботиться об их обслуживании и поощрять самообслуживание.
        </p>
      </div>

      <div class="it-solutions__advantages">
        <div>
          <span class="it-solutions__advantages_num">
            2
          </span>
        </div>
        <p class="it-solutions__advantages_text">
          Специалисты КОМПЛИТ ИС уверены, что <b>идеальное решение OSS/BSS</b> — это не застывший во времени продукт,
          а
          процесс, адаптивная система, обладающая способностью меняться и соответствовать изменениям в конкурентной
          среде и бизнесе оператора.
        </p>
      </div>

      <div class="it-solutions__advantages">
        <div>
          <span class="it-solutions__advantages_num">
            3
          </span>
        </div>
        <p class="it-solutions__advantages_text">
          <b>Адаптивное решение не должно состоять из компонентов одного вендора</b>, потому что структура рынка OSS/BSS
          сложна
          и многообразна, и на разных направлениях есть свои лидеры. В то же время ядро этой системы, обеспечивающее
          ключевые бизнес-процессы, по возможности должно быть единой платформой. Остальные компоненты должны уметь
          взаимодействовать с ядром через открытые интерфейсы.
        </p>
      </div>

      <div class="it-solutions__advantages">
        <div>
          <span class="it-solutions__advantages_num">
            4
          </span>
        </div>
        <p class="it-solutions__advantages_text">
          <b>В качестве основной</b> платформы, на которой ведется разработка и дальнейшее промышленное развертывание услуг, <b>была выбрана платформа HPE OCCP.</b> Благодаря ее архитектуре разрабатываемые услуги интегрируются практически с любыми информационными системами оператора связи. Таким образом, обеспечивается конвергентность услуг и в случае смешанной сети оператора (например, мобильный оператор с набором услуг фиксированной связи).
        </p>
      </div>

      <div class="it-solutions__advantages">
        <div>
          <span class="it-solutions__advantages_num">
            5
          </span>
        </div>
        <p class="it-solutions__advantages_text">
          <b>Богатый опыт инженерной команды</b> КОМПЛИТ ИС позволяет учесть все возможные нюансы и предложить
          индивидуально
          спроектированные решения в сфере OSS/BSS, точно отвечающие запросам и потребностям заказчика.
        </p>
      </div>

      <div class="it-solutions__advantages">
        <div>
          <span class="it-solutions__advantages_num">
            6
          </span>
        </div>
        <p class="it-solutions__advantages_text">
          <b>При проектировании решений специалисты компании опираются на положения концепции NGOSS </b>(New Generation
          Operations Systems and Software) и модели eTOM, TAM, SID и TNA, разработанные международным сообществом
          Telemanagement Forum.
        </p>
      </div>

    </div>
    <!-- /.it-solutions-subsystems -->

  </div>

</template>

<script>
export default {}
</script>

<style lang="scss" scoped>

</style>
