<template>
  <div class="bottom-header" id="couplingElement" :class="coupling ? 'coupled' : '' ">
    <nav class="header-menu">
      <ul class="header-menu__list">
        <li class="header-menu__item" v-for="item in menu" :key="item.id">
          <img class="cube" :src="item.arrow" alt="">
          <a class="header-menu__link" href="#"
             v-scroll-to="{
                el: item.href,
                duration: item.duration,
                easing: 'ease-out',
                offset: item.offset,
                }"
          >
            {{ item.name }}
          </a>
        </li>
      </ul>
    </nav>
  </div>
  <!-- /.bottom-header -->
</template>

<script>
export default {
    data() {
        const menuItems = [
            {
                id: 1,
                name: 'о компании',
                arrow: '/img/arrow_1.svg',
                href: '#about',
                duration: 500,
                offset: -50
            },
            {
                id: 2,
                name: 'преимущества',
                arrow: '/img/arrow_2.svg',
                href: '#advantages',
                duration: 800,
                offset: -50
            },
            {
                id: 3,
                name: 'услуги',
                arrow: '/img/arrow_3.svg',
                href: '#services',
                duration: 800,
                offset: -150
            },
            {
                id: 4,
                name: 'контакты',
                arrow: '/img/arrow_4.svg',
                href: '#contacts',
                duration: 1200,
                offset: -50
            },
        ];
      return {
          menu: menuItems,
          coupling: false,
          offsetTemp: null
      }
    },
    methods: {

    },
    mounted () {
        const that = this;
        window.addEventListener('scroll', function() {
          if (window.screen.availWidth > 647) {
            let direction = (that.offsetTemp - this.pageYOffset) < 0 ? "down" : "up";
            that.offsetTemp = this.pageYOffset;

            if (direction === "down" && !that.coupling) {
              if (this.pageYOffset >= 100)
                that.coupling = true;
            } else if (direction === "up" && (this.pageYOffset <= 100)) {
              if (that.coupling) {
                that.coupling = false;
              }
            }
        }
        });
    }
}
</script>

<style lang="scss" scoped>
  .coupled {
    position:fixed !important;
    top:0 !important;
  }
.bottom-header {
  background-color: #184e60;
  font-family: 'Formular Light', serif;
  position:relative;
  width:100%;
  z-index: 1000;
  box-shadow: 0 8px 6px -6px rgba(0,0,0,0.7) !important;

  .header-menu {
    max-width: 1140px;
    margin: 0 auto;

    .header-menu__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      list-style: none;
      margin-bottom: 0;

      .header-menu__item {
        text-transform: uppercase;
        font-size: 18px;
        padding: 15px 0;
        cursor: pointer;

        .cube {
          display: none;
        }

        .header-menu__link {
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
  }
}

.bottom-header.bHeader {
  display: block;
}

@media screen and (max-width: 768px) {
  .bottom-header {
    display: none;
  }
}

@media screen and (max-width: 647px) {
  .bottom-header {
    background-color: #ffffff;

    .header-menu {
      .header-menu__list {
        display: flex;
        flex-direction: column;
        height: 100%;

        .header-menu__item {
          display: flex;
          align-items: center;
          padding: 0 1rem;

          .cube {
            width: 40px;
            height: 50px;
            display: block;
            position: relative;
          }

          .header-menu__link {
            color: #000000;
            padding: 0 1rem;
            position: relative;
            width: 100%;

            &::after {
              content: url("/img/arrow.svg");
              position: absolute;
              left: 80%;
            }
          }
        }
      }
    }
  }
}

</style>
