const arr = [
    {
        id: 0,
        title: 'Управление ИТ услугами (IT Operations Management)',
        icon: '/img/icons/SVG/icon_accordion.svg',
        opend: false,
        text: '',
        img: '/img/manager_system1.svg',
        component: 'ItManagement',
        innerList: [
            {
                id: 0,
                innerTitle: 'Постановка процессов ITIL/ITSM',
                innerIcon: '*',
                innerShow: false,
                innerComponent: 'Process',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 1,
            },
            {
                id: 1,
                innerTitle: 'Каталогизация ИТ услуг и разработка ресурсно-сервисной модели управления ИТ',
                innerIcon: '+',
                innerShow: false,
                innerComponent: 'Catalog',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 2
            },
            {
                id: 2,
                innerTitle: 'Автоматизация процессов управления ИТ услугами',
                innerIcon: '+',
                innerShow: false,
                innerComponent: 'ProcessAutomation',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 3
            },
            {
                id: 3,
                innerTitle: 'Постановка и автоматизация процесса управления ИТ финансами',
                innerIcon: '+',
                innerShow: false,
                innerComponent: 'ItFinance',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 4
            },
            {
                id: 4,
                innerTitle: 'Постановка и автоматизация процесса управления ИТ активами',
                innerIcon: '+',
                innerShow: false,
                innerComponent: 'ItAssets',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 5
            },
            {
                id: 5,
                innerTitle: 'Внедрение процесса управление активами ПО (Software Asset Management)',
                innerIcon: '+',
                innerShow: false,
                innerComponent: 'AssetManager',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 6
            },
            {
                id: 6,
                innerTitle: 'Автоматизация процессов управления и мониторинга ИТ инфраструктуры',
                innerIcon: '+',
                innerShow: false,
                innerComponent: 'ItMonitoring',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 7
            }
        ]
    },
    {
        id: 1,
        title: 'Управление разработкой и доставкой ПО (Application Delivery Management)',
        icon: '/img/icons/SVG/icon_2.png',
        opend: false,
        text: '',
        img: '/img/manager_project.svg',
        component: 'DeliveryManagement',
        innerList: [
            {
                id: 0,
                innerTitle: 'Автоматизация процесса управления портфелями, проектами, требованиями (Project and Portfolio Management)',
                innerIcon: '*',
                innerShow: false,
                innerComponent: 'AutomationManagement',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 1,
            },
            {
                id: 1,
                innerTitle: 'Автоматизация процессов разработки ПО (DevOps, Agile)',
                innerIcon: '+',
                innerShow: false,
                innerComponent: 'AutomationDevelopment',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 2
            },
            {
                id: 2,
                innerTitle: 'Внедрение систем автоматизированного тестирования',
                innerIcon: '+',
                innerShow: false,
                innerComponent: 'Testing',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 3
            }
        ]
    },
    {
        id: 2,
        title: 'Robotics Process Automation',
        icon: '/img/icons/SVG/icon_robot.svg',
        opend: false,
        text: '',
        img: '/img/Robotics_Process_Automation.svg',
        component: 'Robotics',
        innerList: [
            {
                id: 0,
                innerTitle: 'Внедрение систем автоматизации рутинных операций (RPA)',
                innerIcon: '*',
                innerShow: false,
                innerComponent: 'Routine',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 1,
            }
        ]
    },
    {
        id: 3,
        title: 'Управление корпоративными услугами (Enterprise Service Management) и Управление активами (Enterprise Asset Management)',
        icon: '/img/icons/SVG/icon_Service_Management.svg',
        opend: false,
        text: '',
        img: '/img/Enterprise_Service_Management.svg',
        component: 'Enterprise',
        innerList: [
            {
                id: 0,
                innerTitle: 'Разработка методик Управления нормативно-справочной информацией (Master Data Management)',
                innerIcon: '*',
                innerShow: false,
                innerComponent: 'MasterData',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 1,
            },
            {
                id: 1,
                innerTitle: 'Внедрение систем MDM',
                innerIcon: '*',
                innerShow: false,
                innerComponent: 'IntegrationMDM',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 2,
            },
            {
                id: 2,
                innerTitle: 'Постановка и автоматизация процесса управления активами предприятия (EAM)',
                innerIcon: '*',
                innerShow: false,
                innerComponent: 'AssetsControl',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 3,
            },
            {
                id: 3,
                innerTitle: 'Системы автоматизации процессов Технического обслуживания и Ремонта',
                innerIcon: '*',
                innerShow: false,
                innerComponent: 'Maintenance',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 4,
            },
            {
                id: 4,
                innerTitle: 'Автоматизация материально-технического обеспечения (МТО)',
                innerIcon: '*',
                innerShow: false,
                innerComponent: 'AutomationMTS',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 5,
            },
            {
                id: 5,
                innerTitle: 'Ситуационный центр для управления комплексами/зданиями: единый центр управления системами, работами, событиями',
                innerIcon: '*',
                innerShow: false,
                innerComponent: 'SingleCentre',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 6,
            },
            {
                id: 6,
                innerTitle: 'Электронный обмен данными (EDI) и интеграция гетерогенных приложений',
                innerIcon: '*',
                innerShow: false,
                innerComponent: 'DataExchange',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 7,
            }
        ]
    },
    {
        id: 4,
        title: 'Indoor-навигация: навигация, мобильные ассистенты и поддержка интегративных видеопанелей',
        icon: '/img/icons/SVG/icon_mobile.svg',
        opend: false,
        text: '',
        img: '/img/mobile_assistant.svg',
        component: 'Indoor',
        innerList: [
            {
                id: 0,
                innerTitle: 'Внедрение систем мобильного позиционирования',
                innerIcon: '*',
                innerShow: false,
                innerComponent: 'Mobile',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 1,
            },
            {
                id: 1,
                innerTitle: 'Мобильное приложение для навигации',
                innerIcon: '*',
                innerShow: false,
                innerComponent: 'MobileApp',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 2,
            },
            {
                id: 2,
                innerTitle: 'Мобильный ассистент для ВУЗ',
                innerIcon: '*',
                innerShow: false,
                innerComponent: 'MobileAssistant',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 3,
            },
            {
                id: 3,
                innerTitle: 'Разработка систем отражения контента для интерактивных видеопанелей',
                innerIcon: '*',
                innerShow: false,
                innerComponent: 'VideoPanels',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 4,
            }
        ]
    },
    {
        id: 5,
        title: 'Автоматизация больниц, аптек, прочих медицинских учреждений',
        icon: '/img/icons/SVG/icon_medicine.png',
        opend: false,
        text: '',
        img: '/img/Medical_Automatization.svg',
        component: 'Medicine',
        innerList: [
            {
                id: 0,
                innerTitle: 'Автоматизация больниц, аптек, прочих медицинских учреждений',
                innerIcon: '*',
                innerShow: false,
                innerComponent: 'MedicineAutomation',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 1,
            },
            {
                id: 1,
                innerTitle: 'Интеграция решений для медицинских учреждений с ИС страховых компаний, ТФОМС, МИАЦ',
                innerIcon: '*',
                innerShow: false,
                innerComponent: 'MedicineIntegration',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 2,
            }
        ]
    },
    {
        id: 6,
        title: 'Решения на базе платформы 1С',
        icon: '/img/icons/SVG/onec.png',
        opend: false,
        text: '',
        img: '/img/one-c.svg',
        component: 'PlatformC',
        innerList: [
            {
                id: 0,
                innerTitle: '1C: CRM',
                innerIcon: '*',
                innerShow: false,
                innerComponent: 'OneCRM',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 1,
            },
            {
                id: 1,
                innerTitle: '1C: Документооборот',
                innerIcon: '*',
                innerShow: false,
                innerComponent: 'DocFlow',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 2,
            },
            {
                id: 2,
                innerTitle: 'Разработка заказных систем на базе 1С',
                innerIcon: '*',
                innerShow: false,
                innerComponent: 'CustomC',
                color: '#009ab8',
                colorNum: 'linear-gradient(90deg, rgb(67 209 242) 0%, rgb(19 77 90) 8.65%, rgb(35, 92, 105) 27.96%, rgb(37, 62, 68) 44.82%, rgb(39, 44, 45) 58.4%, rgb(39, 37, 37) 67.01%)',
                num: 3,
            }
        ]
    }
];
export default {
    getArr() {
        return arr
    },
}
