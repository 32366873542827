<template>
    <div class="inner-list">
        <p>Внедрение методов управления ИТ на основе подхода ITSM с применением наиболее актуальных практических рекомендаций из библиотеки ITIL: обследование существующих процессов управления, реинжиниринг процессов с учетом рекомендаций ITIL/ITSM, построение системы KPI, разработка регламентов процессов. Наши специалисты обладают опытом реализации такого типа проектов и имеют сертификации по ITIL в том числе и экспертного уровня.</p>
    </div>
</template>

<script>
    export default {
        name: "Process"
    }
</script>

<style scoped>

</style>