<template>
  <div class="service-management" id="services">
    <div class="service-management__title">
      <div class="service-management__title_left"></div>
      <div class="service-management__title_center">
          <h2>УПРАВЛЕНИЕ&nbsp;ИТ&#8209;УСЛУГАМИ<br>И&nbsp; БИЗНЕС&#8209;ПРОЦЕССАМИ</h2>
      </div>
    </div>
    <div class="container main-wrap">
      <div class="service-management__desc">
        <p>Сотрудники Комплит ИС имеют обширный опыт разработки систем для автоматизации бизнес-процессов, процессов управления информационными системами и проектами. Наши специалисты – сертифицированные профессионалы со стажем работы по данному направлению от семи лет.</p>
        <p>Сотрудники, участвующие в проектах департамента, имеют сертификации по направлениям:</p>
      </div>
      <div class="service-management__list">
      <div class="service-management__list_column">
        <ul>
          <li><span>ITIL v.3 Foundation</span></li>
          <li><span>ITIL v.4 Foundation</span></li>
          <li><span>ITIL v.3 Expert</span></li>
          <li><span>Project Management Professional (PMP)</span></li>
        </ul>
      </div>
      <div class="service-management__list_column">
        <ul>
          <li><span>1С:Профессионал, 1С:Специалист – платформа 1С:Предприятие</span></li>
          <li><span>1С:Профессионал – УПП, Бухгалтерия, ЗУП, Управление торговлей</span></li>
          <li><span>1С:Профессионал – Медицина</span></li>
          <li><span>1С:Профессионал по технологическим вопросам</span></li>
        </ul>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceManagement"
}
</script>

<style lang="scss" scoped>

</style>
