import Vue from 'vue'

import vueLayoutHead from "./Layout/LayoutHead/LayoutHead.vue"
import vueLayoutFoot from "./Layout/LayoutFoot/LayoutFoot"
// import vueDynamicComponent from "./DataFields/dynamic-component.vue"
import vueBaseLayout from "./Layout/BaseLayout"

Vue.component('LayoutHead', vueLayoutHead)
Vue.component('LayoutFoot', vueLayoutFoot)
// Vue.component('dynamic-component', vueDynamicComponent)
Vue.component('BaseLayout', vueBaseLayout)
