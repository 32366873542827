<template>
    <div class="inner-list">
        <p>Проведение проектов по автоматизации процессов управления ИТ на базе решений ведущих лидеров рынка систем автоматизации управления ИТ процессами. Автоматизируемые процессы: Управление  Конфигурациями, Управление Каталогом ИТ услуг, Help Sesk/Service Desk, Управление Инцидентами, Проблемами, Изменениями, SLM, Управление запросами.</p>
    </div>
</template>

<script>
    export default {
        name: "ProcessAutomation"
    }
</script>

<style scoped>

</style>