<template>
    <div class="inner-list">
        <p>Автоматизация функций планирования МТО, планирования процессов обеспечения потребностей в МТР, исполнения плана обеспечения, оперативный контроль и анализ исполнения потребностей в МТР.</p>
    </div>
</template>

<script>
    export default {
        name: "automationMTS"
    }
</script>

<style scoped>

</style>