var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"form-wrap mb-5"},[_c('form',{attrs:{"id":"cis-form"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"form-floating"},[_c('label',{attrs:{"for":"message"}}),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.message),expression:"form.message"}],staticClass:"form-control",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.form.message.$error,
                        },staticStyle:{"height":"150px"},attrs:{"placeholder":"Напишите нам","id":"message"},domProps:{"value":(_vm.form.message)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "message", $event.target.value)}}}),(_vm.submitted && !_vm.$v.form.message.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Пожалуйста, введите текст сообщения ")]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control",class:{
                                'is-invalid':
                                    _vm.submitted && _vm.$v.form.name.$error,
                            },attrs:{"placeholder":"Имя","type":"text","id":"name","name":"name"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "name", $event.target.value)}}}),(_vm.submitted && !_vm.$v.form.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Пожалуйста, введите ваше имя ")]):_vm._e()])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"companyName"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.companyName),expression:"form.companyName"}],staticClass:"form-control",class:{
                                'is-invalid':
                                    _vm.submitted && _vm.$v.form.companyName.$error,
                            },attrs:{"placeholder":"Компания","type":"text","id":"companyName","name":"companyName"},domProps:{"value":(_vm.form.companyName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "companyName", $event.target.value)}}}),(
                                _vm.submitted && !_vm.$v.form.companyName.required
                            )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Пожалуйста, введите название компании ")]):_vm._e()])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"phone"}}),_c('the-mask',{staticClass:"form-control",class:{
                                'is-invalid':
                                    _vm.submitted && _vm.$v.form.phone.$error,
                            },attrs:{"mask":"+7 (###) ###-####","value":"","type":"tel","masked":true,"placeholder":"+7 (___) ___-____"},on:{"change":function($event){return _vm.$v.form.phone.$touch()}},model:{value:(_vm.$v.form.phone.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.phone, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.phone.$model"}}),(_vm.submitted && _vm.$v.form.phone.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.phone.required)?_c('span',{staticClass:"error"},[_vm._v("Пожалуйста, введите номер телефона")]):_vm._e(),(!_vm.$v.form.phone.phoneNumber)?_c('span',{staticClass:"error"},[_vm._v("Номер телефона введен неверно")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control",class:{
                                'is-invalid':
                                    _vm.submitted && _vm.$v.form.email.$error,
                            },attrs:{"placeholder":"E-mail","type":"email","id":"email","name":"email"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "email", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.email.required)?_c('span',[_vm._v("Пожалуйста, заполните поле Email")]):_vm._e(),(!_vm.$v.form.email.email)?_c('span',[_vm._v("Email введен неверно")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"form-check form-check-inline my-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.flexCheckDefault),expression:"form.flexCheckDefault"}],staticClass:"form-check-input",attrs:{"type":"checkbox","id":"flexCheckDefault"},domProps:{"checked":Array.isArray(_vm.form.flexCheckDefault)?_vm._i(_vm.form.flexCheckDefault,null)>-1:(_vm.form.flexCheckDefault)},on:{"change":function($event){var $$a=_vm.form.flexCheckDefault,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "flexCheckDefault", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "flexCheckDefault", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "flexCheckDefault", $$c)}}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"flexCheckDefault"}},[_vm._v(" Согласен на обработку персональных данных ")])]),_vm._m(1)])])]),_vm._m(2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-center m-auto form-header"},[_vm._v(" Если у вас появились вопросы, просто укажите свои контактные данные в форме ниже."),_c('br'),_vm._v(" Наш менеджер свяжется с вами в ближайшее время. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"custom-text"},[_vm._v(" Отправляя форму обратной связи, "),_c('a',{staticClass:"custom-link",attrs:{"href":"/docs/agreement.pdf","target":"_blank"}},[_vm._v("вы соглашаетесь")]),_vm._v(" с условиями "),_c('a',{staticClass:"custom-link",attrs:{"href":"/docs/Политика конфиденциальности КИС.pdf","target":"_blank"}},[_vm._v("обработки и использования персональных данных")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group button-box"},[_c('button',{staticClass:"btn button-custom my-3"},[_vm._v(" Отправить ")])])])
}]

export { render, staticRenderFns }