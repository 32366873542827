<template>
  <div class="copyright">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 text-center">
          <img class="footer__logo" src="/img/logo.png" alt="">
        </div>
        <div class="col-md-6 text-center">
          <a class="footer__phone" :href="phone">
            <span>8 812 386 90 03</span>
          </a>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-md-6 text-center">
          <p class="copyright__rights">&copy; Все права защищены</p>
        </div>
        <div class="col-md-6 text-center">
          <a class="footer__mail" :href="mail">
            <span>info-is@complete.ru</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      img: {
        logo: '/img/logo.png'
      },
      mail: 'mailto:info-is@complete.ru',
      phone: 'tel:88123869003',
    }
  },
}
</script>

<style lang="scss" scoped>
.copyright {
  .copyright__rights {
    color: #ffffff;
    font-family: 'Formular Light';
    font-size: 14px;
    padding-top: 15px;
  }

  .footer__logo {
    max-width: 164px;
  }

  .footer__phone, .footer__mail {
    color: #ffffff;
    text-decoration: none;
  }

  .footer__phone {
    font-family: 'Formular Medium';
  }

  .footer__mail {
    font-family: "Formular Light";
  }
}
</style>
