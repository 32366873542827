<template>
    <div class="inner-list">
        <p>
            Обеспечение автоматизированного обмена данными и электронными документами с ИС организаций здравоохранения, фондами обязательного медицинского страхования, страховыми организациями.
        </p>
    </div>
</template>

<script>
    export default {
        name: "MedicineIntegration"
    }
</script>

<style scoped>

</style>