<template>
  <div class="container accordion-wrapp">
    <div class="accordion" v-for="item in accordion" :key="item.id">
      <h2 class="accordion__title" @click="item.opend = !item.opend" :class="{ active: item.opend }">
        <img class="img-fluid icon" :src="item.icon" alt="">
        <span class="accordion__title_text">{{ item.title }}</span>
      </h2>
      <transition name="slide-fade">
        <div class="accordion-show" v-show="item.opend">
          <div>
            <component :is="item.component"></component>
          </div>

          <div class="inner" v-for="elem in item.innerList" :key="elem.id">

            <div class="inner__title" @click="elem.innerShow = !elem.innerShow" :style="{ background: elem.colorNum }">
              <span class="inner__title_num" :class="{ opened: elem.innerShow }">{{ elem.num }}</span>
              <span class="inner__title_text" :style="{ backgroundColor: elem.color }" :class="{ iconActive: elem.innerShow }">{{ elem.innerTitle }}</span>
            </div>
            <!-- /.inner__title -->

            <transition name="slide-fade">
              <div class="inner__item" v-show="elem.innerShow">
                <component :is="elem.innerComponent"></component>
              </div>
              <!-- /.inner__item -->
            </transition>


          </div>
          <!-- /.inner -->

        </div>
        <!-- /.list -->
      </transition>
    </div>
    <!-- /.accordion -->
  </div>
</template>


<script>

import dataAccordion from './data'
import ItManagement from "./ItManagement/ItManagement";
import Process from "./ItManagement/InnerList/Process";
import Catalog from "./ItManagement/InnerList/Catalog";
import ProcessAutomation from "./ItManagement/InnerList/ProcessAutomation";
import ItFinance from "./ItManagement/InnerList/ItFinance";
import ItAssets from "./ItManagement/InnerList/ItAssets";
import AssetManager from "./ItManagement/InnerList/AssetManager";
import ItMonitoring from "./ItManagement/InnerList/ItMonitoring";
import DeliveryManagement from "./DeliveryManagement/DeliveryManagement";
import AutomationManagement from "./DeliveryManagement/InnerList/AutomationManagement";
import AutomationDevelopment from "./DeliveryManagement/InnerList/AutomationDevelopment";
import Testing from "./DeliveryManagement/InnerList/Testing";
import Robotics from "./Robotics/Robotics";
import Routine from "./Robotics/InnerList/Routine";
import Enterprise from "./Enterprise/Enterprise";
import MasterData from "./Enterprise/InnerList/MasterData";
import IntegrationMDM from "./Enterprise/InnerList/IntegrationMDM";
import AssetsControl from "./Enterprise/InnerList/AssetsControl";
import Maintenance from "./Enterprise/InnerList/Maintenance";
import AutomationMTS from "./Enterprise/InnerList/AutomationMTS";
import SingleCentre from "./Enterprise/InnerList/SingleCentre";
import DataExchange from "./Enterprise/InnerList/DataExchange";
import Indoor from "./Indoor/Indoor";
import Mobile from "./Indoor/InnerList/Mobile";
import MobileApp from "./Indoor/InnerList/MobileApp";
import MobileAssistant from "./Indoor/InnerList/MobileAssistant";
import VideoPanels from "./Indoor/InnerList/VideoPanels";
import Medicine from "./Medicine/Medicine";
import MedicineAutomation from "./Medicine/InnerList/MedicineAutomation";
import MedicineIntegration from "./Medicine/InnerList/MedicineIntegration";
import PlatformC from "./PlatformC/PlatformC";
import OneCRM from "./PlatformC/InnerList/OneCRM";
import DocFlow from "./PlatformC/InnerList/DocFlow";
import CustomC from "./PlatformC/InnerList/CustomC";

export default {
  data() {
    const accordion = dataAccordion.getArr();
    return {
      accordion,
    }
  },
  components: {
    ItManagement,
    Process,
    Catalog,
    ProcessAutomation,
    ItFinance,
    ItAssets,
    AssetManager,
    ItMonitoring,
    DeliveryManagement,
    AutomationManagement,
    AutomationDevelopment,
    Testing,
    Robotics,
    Routine,
    Enterprise,
    MasterData,
    IntegrationMDM,
    AssetsControl,
    Maintenance,
    AutomationMTS,
    SingleCentre,
    DataExchange,
    Indoor,
    Mobile,
    MobileApp,
    MobileAssistant,
    VideoPanels,
    Medicine,
    MedicineAutomation,
    MedicineIntegration,
    PlatformC,
    OneCRM,
    DocFlow,
    CustomC
  }
}
</script>

<style lang="scss" scoped>

</style>
