<template>
  <section class="about" id="about">
    <div class="fluid-header w-100">
      <div class="taper left"></div>
      <h1 class="section-title text-center">О КОМПАНИИ</h1>
      <div class="taper right"></div>
    </div>
    <div class="container main-wrap">
      <div class="about__text">
        <p>Компания занимается созданием информационных систем, специализируясь на разработке и доработке программного обеспечения для автоматизации бизнес-процессов.</p>
          <p>Команда Комплит ИС имеет огромный опыт консалтинга, разработки и внедрения информационных систем, автоматизирующих процессы управления корпоративными услугами, ИТ услугами и инфраструктурой, доставкой ПО, управления активами, нормативно-справочной информацией, включая внедрение роботизации автоматизированных процессов. Помимо основных направлений, мы разрабатываем мобильные приложения, системы автоматизации, Indoor-навигации для торгово-развлекательных комплексов, музеев, вузов, медицинских учреждений.</p>
          <p>Работа над проектами основана на современных подходах к разработке и внедрению программных продуктов, что позволяет очень гибко и оперативно реагировать на потребности заказчиков.</p>
          <p>
          Мы предлагаем весь спектр услуг по оптимизации работы ИС:
        </p>
      </div>

      <!-- /.about__text -->
    </div>
    <!-- /.container -->
    <div class="container main-wrap changed-padding">
      <div class="row">
        <AboutIcons></AboutIcons>
      </div>
    </div>
    <!-- /.container -->
  </section>
</template>

<script>

import AboutIcons from "./AboutIcons";

export default {
  name: "About",
  components: {
    AboutIcons,
  },
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
#about {
  position: relative;
  padding-top: 100px;

  @media screen and (min-width: 0) and (max-width: 600px) {
    padding-top: 60px;
  }
  @media screen and (min-width: 601px) and (max-width: 900px) {
    padding-top: 70px;
  }

  .fluid-header {
    position: absolute;
    display: flex;
    align-items: stretch;

    .taper {
      height: 1em;
      background: url('/img/about-tape-image.jpg') repeat-x;
      background-size: auto 75%;
    }

    .taper.left {
      flex-grow: 1;
      background-position: right 55%;
    }

    .taper.right {
      flex-grow: 1;
      background-position: left 55%;
    }

    > h1 {
      width: auto;
      height: 1em;
      line-height: 1em;
      margin: 0;
      vertical-align: center;


    }

  }

  .tape-wrapper, .fluid-header > * {
    font-size: 3em;
    @media screen and (min-width: 0) and (max-width: 600px) {
      margin: auto;
      font-size: 1em;
    }
    @media screen and (min-width: 601px) and (max-width: 900px) {
      font-size: 2rem;
    }
  }

  .tape-wrapper {
    display: block;
    position: static;
    height: 1em !important;
  }

  > div.container {
    padding-top: 100px;
    @media screen and (min-width: 0) and (max-width: 600px) {
      padding-top: 50px;
    }
    @media screen and (min-width: 601px) and (max-width: 900px) {
      padding-top: 70px;
    }
  }
}

#about > div.container {
  padding-top: 60px;
  @media screen and (max-width: 500px){
    padding-top: 20px;
  }
}
</style>
