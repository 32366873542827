<template>
    <div class="icons-wrap">
        <div class="icons-wrap__large mt-5 mb-5">
            <div class="service-icon consult">
                <div class="desc-consult"
                     v-on:mouseover="zoomOn($event.target)"
                     v-on:mouseleave="zoomOf($event.target)"
                >КОНСАЛТИНГ И ПОСТАНОВКА<br>ПРОЦЕССОВ УПРАВЛЕНИЯ</div>
                <img class="" src="/img/icons/SVG/2.svg" alt=""
                     v-on:mouseover="zoomOn($event.target)"
                     v-on:mouseleave="zoomOf($event.target)"
                >
            </div>
            <div class="service-icon study">

                <img class="" src="/img/icons/SVG/5.svg" alt=""
                     v-on:mouseover="zoomOn($event.target)"
                     v-on:mouseleave="zoomOf($event.target)"
                >
                <div class="desc-study"
                     v-on:mouseover="zoomOn($event.target)"
                     v-on:mouseleave="zoomOf($event.target)"
                >АНАЛИЗ ТРЕБОВАНИЙ И ПОДБОР<br>ЭФФЕКТИВНОГО РЕШЕНИЯ</div>
            </div>
            <div class="service-icon equipment">
                <div class="desc-equipment"
                     v-on:mouseover="zoomOn($event.target)"
                     v-on:mouseleave="zoomOf($event.target)"
                >РАЗРАБОТКА<br>И ВНЕДРЕНИЕ ИС</div>
                <img class="" src="/img/icons/SVG/4.svg" alt=""
                     v-on:mouseover="zoomOn($event.target)"
                     v-on:mouseleave="zoomOf($event.target)"
                >

            </div>
            <div class="service-icon modern">
                <img class="" src="/img/icons/SVG/6.svg" alt=""
                     v-on:mouseover="zoomOn($event.target)"
                     v-on:mouseleave="zoomOf($event.target)"
                >
                <div class="desc-modern"
                     v-on:mouseover="zoomOn($event.target)"
                     v-on:mouseleave="zoomOf($event.target)"
                >СОПРОВОЖДЕНИЕ<br>И МОДЕРНИЗАЦИЯ ИС</div>
            </div>
        </div>
        <div class="icons-wrap__small mt-1 mb-5">
            <img src="/img/icons/SVG/service-mobile2.png" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        name: "AboutIcons",
        data() {
            return {

            }
        },
        methods: {
            zoomOn(el) {
                let zoom = el.parentElement.children;
                let pointer = [...zoom];
                pointer.forEach(function(child) {
                    child.classList.add('scale');
                })
            },
            zoomOf(el) {
                let zoom = el.parentElement.children;
                let pointer = [...zoom];
                pointer.forEach(function(child) {
                    child.classList.remove('scale');
                });
            }
        }
    }
</script>

<style scoped lang="scss">
.icons-wrap__large{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    height: 190px;
    @media screen and (max-width: 768px){
        display: none;
    }
    .service-icon{
        position: relative;
        margin-left: -15px;
        margin-right: -15px;
        img{
            width: 150px;
            height: 130px;
            display: block;
            -moz-transition: all .2s ease-out;
            -o-transition: all .2s ease-out;
            -webkit-transition: all .2s ease-out;
        }
    }
    .consult, .equipment{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        top: -70px;
        div{
            font-size: .7rem;
            position: absolute;
            top: -20px;
            text-align: center;
            overflow: visible;
            font-weight: bold;
            -moz-transition: all .2s ease-out;
            -o-transition: all .2s ease-out;
            -webkit-transition: all .2s ease-out;
        }

        .desc-consult{
            width: 200px;
            color: #197d9b;
        }
        .desc-equipment{
            color: #239dce;
        }
    }
    .modern, .study{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        div{
            font-size: .7rem;
            position: absolute;
            bottom: -20px;
            text-align: center;
            overflow: visible;
            font-weight: bold;
            -moz-transition: all .5s ease-out;
            -o-transition: all .5s ease-out;
            -webkit-transition: all .5s ease-out;
        }
        .desc-modern {
            color:#49cec1;
        }
        .desc-study {
            color: #45afab;
            width: 210px;
        }
    }
}
    .scale{
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -o-transform: scale(1.2);
        z-index: 9;
    }
    .icons-wrap__small{
        padding-right: 10px;
        padding-left: 10px;
        @media screen and (min-width: 767px){
            display: none;
        }
        img{
            width: 100%;
        }
    }
</style>