<template>
    <div class="inner-list">
        <p>Внедрение процесса управления ресурсами, формирующими ИТ инфраструктуру предприятия. Данный процесс обеспечивает возможность планирования, учёта и отслеживания состояния ИТ-активов (элементов ИТ инфраструктуры), которые определяют измеримую ценность для основной деятельности организации и объединяет финансовый, контрактный и учетный аспекты управления жизненным циклом ИТ-активов, предоставляя возможность оперативного контроля и принятия стратегических решений для ИТ-среды.</p>
    </div>
</template>

<script>
    export default {
        name: "ItAssets"
    }
</script>

<style scoped>

</style>