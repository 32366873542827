<template>
    <div class="inner-list">
        <p>
            Автоматизация процессов ведения клиентской базы, управление контактами, управление рабочим временем, управление бизнес-процессами, управление продажами, управление маркетингом. На базе решений на платформе 1С.
        </p>
    </div>
</template>

<script>
    export default {
        name: "OneCRM"
    }
</script>

<style scoped>

</style>