<template>
    <div class="top-slider">
        <div class="left-slide">
            <div class="left-slide__bg"
                :class="{ scale:leftZoom }"
            >
            </div>
            <a href="#"
                v-on:mouseover="leftZoom=!leftZoom"
               v-on:mouseleave="leftZoom=!leftZoom"
               v-scroll-to="{
                el: '#services',
                duration: '1500',
                easing: 'ease-out',
                offset: -150,
                }"
            >
                <img src="/img/bg2.png" alt="">
            </a>
        </div>
        <div class="right-slide">
            <div class="right-slide__bg"
                 :class="{ scale: rightZoom }"
            >
            </div>
            <a href="#"
               v-on:mouseover="rightZoom=!rightZoom"
               v-on:mouseleave="rightZoom=!rightZoom"
               v-scroll-to="{
                el: '#it-solutions',
                duration: '1500',
                easing: 'ease-out',
                offset: -150,
                }"
            >
                <img src="/img/bg3.png" alt="">
            </a>
        </div>
        <div class="top-slider__mobile">
            <a href="#" class="top-link"
               v-scroll-to="{
                el: '#services',
                duration: '1500',
                easing: 'ease-out',
                offset: -150,
                }"
            >
                <img src="/img/bg2.png" alt="">
            </a>
            <a href="#" class="bottom-link"
               v-scroll-to="{
                el: '#it-solutions',
                duration: '1500',
                easing: 'ease-out',
                offset: -150,
                }"
            >
                <img src="/img/bg3.png" alt="">
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TopSlider",
        data() {
            return {
                leftZoom: false,
                rightZoom: false
            }
        }
    }
</script>

<style scoped lang="scss">
    .top-slider{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 664px;
        @media screen and (max-width: 500px){
            height: 500px;
        }
        .left-slide{
            width: 50%;
            height: 100%;
            overflow: hidden;
            position: relative;
            @media screen and (max-width: 767px) {
                display: none;
            }

            &__bg{
                width: 100%;
                height: 100%;
                background: url("/img/left2.png") center center no-repeat;
                background-size: cover;
                position: absolute;
                -moz-transition: all .5s ease-out;
                -o-transition: all .5s ease-out;
                -webkit-transition: all .5s ease-out;
            }
            & a {
                display: block;
                position: absolute;
                max-width: 50%;
                top: 30%;
                left: 40%;
                @media screen and (max-width: 1140px){
                    max-width: 75%;
                    top: 20%;
                    left: 5%;
                }
                & img {
                    width: 100%;
                }
            }
        }
        .right-slide{
            width: 50%;
            height: 100%;
            overflow: hidden;
            position: relative;
            @media screen and (max-width: 767px) {
                display: none;
            }
            &__bg{
                position: absolute;
                width: 100%;
                height: 100%;
                background: url("/img/right2.png") center center no-repeat;
                background-size: cover;
                -moz-transition: all .5s ease-out;
                -o-transition: all .5s ease-out;
                -webkit-transition: all .5s ease-out;
            }
            & a {
                display: block;
                position: absolute;
                max-width: 50%;
                top: 37%;
                right: 40%;
                @media screen and (max-width: 1140px){
                    max-width: 75%;
                    top: 45%;
                    right: 5%;
                }
                & img{
                    width: 100%;
                }
            }
        }
        &__mobile{
            @media screen and (min-width: 768px) {
                display: none;
            }
            position: relative;
            background: url("/img/left2.png") center center no-repeat;
            background-size: cover;
            width: 100%;
            height: 100%;
            & .top-link{
                position: absolute;
                top:10%;
                left: 5%;
                width: 65%;
                @media screen and (max-width: 400px){
                    width: 80%;
                }
                img {
                    width: 100%;
                }
            }
            & .bottom-link{
                position: absolute;
                top: 50%;
                right: 5%;
                width: 65%;
                @media screen and (max-width: 400px){
                    width: 80%;
                }
                img {
                    width: 100%;
                }
            }
        }
        .scale{
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -o-transform: scale(1.1);
        }
    }
</style>