<template>
  <div class="d-flex flex-column h-100">
    <header>
      <slot name="header"></slot>
    </header>
    <main>
      <slot></slot>
    </main>
    <footer class="footer mt-auto py-4">
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
header {

}

main {

}

footer {
  background-color: #1a3842;
}
</style>
