<template>
    <div class="inner-list mt-5">

        <div class="special-marker-top__row">
            <div class="special-marker-top__row_list special-marker-top__row_first">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker-top__row_text">
                <p><strong>Автоматизация жизненного цикла разработки ПО по методикам waterfall и Agile</strong></p>
                <ul>
                    <li>поддержка цикла работы Agile команд</li>
                    <li>автоматизация сбора требований и назначения задач, task management</li>
                </ul>
            </div>
        </div>
        <div class="special-marker-top__row">
            <div class="special-marker-top__row_list special-marker-top__row_last">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker-top__row_text">
                <p><strong>DevOps – автоматизация</strong></p>
                <ul>
                    <li>непрерывная интеграция (сборка,автотесты, деплой)</li>
                    <li>интеграция с системами разработки, непрерывной интеграции, тестирования</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AutomationDevelopment"
    }
</script>

<style scoped>

</style>