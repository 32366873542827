<template>
    <div class="inner-list">
        <p>Разработка систем классификации активов, создание классов и шаблонов классов: перечень свойств и характеристик класса, разработка системы вертикально-горизонтальных связей активов.</p>
        <p>Внедрение процесса управления Активами предприятия. Данный процесс обеспечивает возможность планирования, учёта и расчета TCO активов предприятия, определяющих измеримую ценность для основной деятельности организации, и объединяет финансовый, контрактный и учетный аспекты управления жизненным циклом ИТ-активов.</p>
    </div>
</template>

<script>
    export default {
        name: "AssetsControl"
    }
</script>

<style scoped>

</style>