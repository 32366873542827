<template>
    <div id="presendceMap"></div>
</template>

<script>
import * as d3 from "d3"
import shapeRussia from "./geodata/russia.json"
import presenceData from "./geodata/russian-cities.json"
import shapeWorld from "./geodata/world.json"

// const log = (...things) => console.log(things)
// log(this)

const margin = {top: 30, right: 20, bottom: 30, left: 50};
//const width = 1344 - margin.left - margin.right;
const height = 822 - margin.top - margin.bottom;
const projectionOffsetH = -300
const projectionOffsetV = 1350
const scale = 1000
const projection = d3.geoMercator().scale(scale).translate([projectionOffsetH, projectionOffsetV]);
const markerOffsetV = projectionOffsetV - height
//markerOffsetH = projectionOffsetH - width
const vbHeight = 650;
const vbWidth = vbHeight * Math.sqrt(2);
const markerPath = "m12.031 1030.4c-3.8657 0-6.9998 3.1-6.9998 7 0 1.3 0.4017 2.6 1.0938 3.7 0.0334 0.1 0.059 0.1 0.0938 0.2l4.3432 8c0.204 0.6 0.782 1.1 1.438 1.1s1.202-0.5 1.406-1.1l4.844-8.7c0.499-1 0.781-2.1 0.781-3.2 0-3.9-3.134-7-7-7zm-0.031 3.9c1.933 0 3.5 1.6 3.5 3.5 0 2-1.567 3.5-3.5 3.5s-3.5-1.5-3.5-3.5c0-1.9 1.567-3.5 3.5-3.5z";


export default {
    name:"presenceMap",
    data() {
        
        return {
            shapeRussia: shapeRussia,
            shapeWorld: shapeWorld,
            presenceData:presenceData,
            path: d3.geoPath().projection(projection),
            createMap: () => new Promise( (resolve) => {
                resolve( {
                    
                    map: d3.select("div#presendceMap")
                        .append("svg")
                        .attr("preserveAspectRatio", "xMinYMin meet")
                        .attr("viewBox", "0 0 "+vbWidth+" "+vbHeight+"")
                        .style("background","#008fcf")
                        .append("g")
                        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
                })
            })
        }
    },

    methods: {
        drawWorldShape(map) {
            map.selectAll("path.shape-world")
                .data(this.shapeWorld.features)
                .enter().append("path")
                .attr("d", this.path)
                .attr("class", "shape-world")
        },
        drawRussianShape(map) {
            map.selectAll("path.shape-russia")
                .data(this.shapeRussia.features)
                .enter().append("path")
                .attr("d", this.path)
                .attr("class", "shape-russia")
        },
        drawLabels(map) {

            map.append("linearGradient")
                .attr("id", "tooltipGradient")
                .attr("x1", "0%")
                .attr("y1", "0%")
                .attr("x2", "0%")
                .attr("y2", "100%")
                .selectAll("stop")
                .data([{"offset":"5%", "stop-color":"#D8D8D8"},{"offset":"95%", "stop-color":"#ccc"}])
                .enter()
                .append("stop")
                .attr("offset", (d) => d.offset)
                .attr('stop-color', (d) => d['stop-color'])

            map.selectAll("circle.circles")
                .data(this.presenceData)
                //.enter()
                .append("circle")
                .attr("class","circles")
                .attr("cx", function(d) {return projection([d.coords.lon, d.coords.lat])[0];})
                .attr("cy", function(d) {return projection([d.coords.lon, d.coords.lat])[1];})
                .attr("r", "1px")

            const labelGroups = map.selectAll("g.labelGroup")
                .data(this.presenceData)
                .enter()
                .append("g")
                .attr("class", "labelGroup")
                .attr("transform", function(d) {
                    return "translate(" + 
                    (projection([d.coords.lon, d.coords.lat])[0] - 12) + "," + 
                    (projection([d.coords.lon, d.coords.lat])[1] - projectionOffsetV + 753) + ")"})

            map
                .selectAll("path.marker")
                .data(this.presenceData)
                .enter()
                .append("path")
                .attr("class","marker")
                .attr("d", markerPath)
                .attr("transform", function(d) {
                    return "translate(" + 
                    (projection([d.coords.lon, d.coords.lat])[0]-12) + "," + 
                    (projection([d.coords.lon, d.coords.lat])[1] - 1035 - 12) + ")"})
                .attr("fill", "#f6674e")

            

            labelGroups
                .append("rect")
                .attr("class","dices")
                .attr("x", -3)
                .attr("y", markerOffsetV-13)
                .attr("rx", 15)
                .attr("ry", 20)
                .attr("width", 170)
                .attr("height", 30)
                // .attr("stroke", "#aaa")
                .attr("stroke-linecap","round")
                
                
            labelGroups
                .append("text")
                .text(function(d) {
                    return d.name;
                    })
                .attr("x", 40)
                .attr("y", markerOffsetV + 6)
                .attr("class","labels")


            labelGroups
                .append("rect")
                .attr("class","toggle")
                .attr("x", -5)
                .attr("y", markerOffsetV-15)
                .attr("rx", 5)
                .attr("ry", 5)
                .attr("width", 200)
                .attr("height", 35)
                .attr("opacity",0)
                .on("mouseover", (event,d) => {

                    let left = projection([d.coords.lon, d.coords.lat])[0];
                    let top = projection([d.coords.lon, d.coords.lat])[1];
                    let html = "<div class='tp-block' align='center'><p class='tp'>"+d.address+"</p><p class='tp'>"+d.phone+"</p><p class='tp'>"+d.email+"</p><p>";

                    map.insert('rect')
                        .attr("class", "tooltip-bg")
                        .attr("height", 100)
                        .attr("width", 200)
                        .attr("x", left - 193)
                        .attr("y", top + 5)
                        .attr("rx", 5)
                        .attr("ry", 5)
                        // .attr("fill", "url(#tooltipGradient)")
                        .attr("fill", "#ffffff")
                        .attr("opacity", 0)
                        .attr("stroke", "#008fcf")
                        .transition().duration(200).attr("opacity", .95)
                    
                    let tooltip = map.append("foreignObject")
                        
                        .attr("class", "tooltip-lb")
                        .attr("width", 180)
                        .attr("height", 85)
                        .attr("x", left - 186)
                        .attr("y", top + 10)
                        .attr("opacity", 0)
                        .html(html)
                        
                    tooltip.transition().duration(200).attr("opacity", 1)
                        
                })
                .on("mouseout", function() {
                    map.selectAll('.tooltip-lb').remove();
                    map.selectAll('.tooltip-bg').remove();
                })
        }
    
    },

    mounted () {
        this.createMap().then( ({ map }) => {
            this.drawWorldShape(map);
            this.drawRussianShape(map);
            this.drawLabels(map);
        } )
    }
}
</script>
<style lang="scss">
    #presendceMap {
        position: relative;

        .shape-russia {
            fill: #ffffff;
            stroke: #ffffff;
            stroke-width: 0.5px;
        }
        .shape-world {
            fill: #0077c6;
            /*stroke: #e0cabc;
            stroke-width: 0.5px;*/
        }
        div.tooltip-lb {
            opacity:0;
            transition: opacity 2s;
        }
        div.tooltip-bg {

        }
        .tp {
            margin:5px;
        }
        .tp-block, .tp {
            //font: 15px italic !important;
          font-family: "Formular Light", sans-serif;

            padding:0;
        }
        .dices {
            fill: #f3f3f3;
        }
        .tooltipBox {
            fill: #eee;
        }
        text.labels  {
            font: 12px sans-serif;
          text-transform: uppercase;
        }
        textArea.address,textArea.phone, textArea.email {
            font: 18px sans-serif !important;
        }
    }
</style>
