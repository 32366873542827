<template>
    <div class="inner-list">
        <p>Внедрение автоматизированных систем, обеспечивающих всеобъемлющий мониторинг и измерение показателей качества/производительности/доступности ИТ услуг и ИТ инфраструктуры. Внедряемые современные системы на базе AIOps позволяют выстраивать зависимость между всеми включенными в мониторинг событиями, автоматически принимать решения об устранении проблем и разрешении инцидентов. Система позволяет подключать к анализу не только ИТ оборудование, но и собирать данные с инженерных систем предприятия, учитывать их в анализе происходящих событий, построении аналитических отчетов. Средства предиктивной аналитики позволяют в значительной степени снизить вероятности простоя производства и снижения уровня предоставляемых услуг.</p>
    </div>
</template>

<script>
    export default {
        name: "ItMonitoring"
    }
</script>

<style scoped>

</style>