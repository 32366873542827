<template>
    <div class="inner-list">
        <p>Настройка электронного обмена данными между сервисами/системами на базе решений класса ESB (MS BizTalk Server и Apache Camel)</p>
    </div>
</template>

<script>
    export default {
        name: "DataExchange"
    }
</script>

<style scoped>

</style>