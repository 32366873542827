<template>
  <div class="geography">
    <div class="container">
      <div class="row align-items-center reverse">
        <div class="col-md-4">
          <div class="geography__main">
            <h2 class="geography__title">ГЕОГРАФИЯ ПРИСУТСТВИЯ</h2>
          </div>
        </div>
        <div class="col-md-8">
          <div class="geography__map">
            <customMap></customMap>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import customMap from './customMap'

export default {
  name: "Geography",
  components: {customMap}
}
</script>

<style lang="scss" scoped>
.geography {
  background-color: #1a6bb2;
  padding: 50px 0;
  margin: 50px 0 0 0;

  .geography__main {
    display: flex;
    justify-content: center;
    text-align: right;

    .geography__title {
      font-family: 'Formular Medium', sans-serif;
      color: #ffffff;
      max-width: 250px;
    }
  }

  .geography__map {
    margin-top: -100px;
    padding: 10px;
    background: white;

    .geography__map-location {

    }
  }
}

@media screen and (max-width: 768px) {
  .geography {
    margin: 0;

    .geography__main {

      .geography__title {
        max-width: 100%;
        padding-top: 3rem;
      }
    }

    .geography__map {
      //margin-top: -100px;

    }

    .reverse {
      flex-direction: column-reverse;
    }
  }
}

@media screen and (max-width: 380px) {
  .geography {
    margin: 0;

    .geography__main {

      .geography__title {
        font-size: 1em;
      }
    }
  }
}
</style>
