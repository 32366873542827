<template>
  <div>

      <div class="container">
          <p>
              В ООО «Комплит ИС» проведена СОУТ на основании: Федерального закона Российской Федерации N 426-ФЗ «О специальной оценке условий труда», приказа Минтруда России №33н от 24.01.2014 «Об утверждении Методики проведения специальной оценки условий труда, Классификатора вредных и (или) опасных производственных факторов, формы отчета о проведении специальной оценки условий труда и инструкции по её заполнению». Согласно Перечня рекомендуемых мероприятий по улучшению условий труда мероприятия по улучшению условий труда не требуются.
          </p>
          <a class="link-file" href="/docs/КИС.pdf" target="_blank">Сводная ведомость результатов проведения специальной оценки условий
              труда</a>
      </div>
      

    <div class="contacts" id="contacts">

      <div class="fluid-header w-100">

        <div class="taper left"></div>
        <h1 class="section-title text-center">КОНТАКТЫ</h1>
        <div class="taper right"></div>

      </div>
      <div class="tape-wrapper"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6 d-flex align-items-center">
            <div class="contacts__address">
              <h2>
                ООО «КОМПЛИТ ИС»
              </h2>
              <p>
                Адрес юридический и фактический:<br> 197345, Санкт-Петербург, <br>
                ул. Яхтенная, 22, корп. 1, лит. А, пом. 263
              </p>
              <p>
                ОГРН: 1197847200540
              </p>
              <p>
                +7 (812) 386 90 03<br>
                info-IS@complete.ru
              </p>
            </div>
          </div>
          <div class="col-md-6">

            <div class="contacts__map">
              <yandex-map :coords="[59.996834, 30.219317]" zoom="15" style="width: 100%; height: 400px" :cluster-options="{
                1: { clusterDisableClickZoom: true }
              }" :controls="['typeSelector', 'zoomControl']" map-type="map" :scroll-zoom=false>
                <ymap-marker marker-id="1" marker-type="placemark" :coords="[59.996834, 30.219317]"
                  :icon="{ color: 'red', glyph: 'factory' }" hint-content="КОМПЛИТ"
                  :balloonTemplate="balloonTemplate"></ymap-marker>
              </yandex-map>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Form></Form>
  </div>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import Form from "./Form";

export default {
  data() {
    return {
      placemarks: [
        {
          coords: [59.996834, 30.219317],
          properties: {}, // define properties here
          options: {}, // define options here
          clusterName: "1",
          balloonTemplate: '<div>"Яхтенная улица, 22к1"</div>',
          callbacks: { click: function () { } }
        }
      ]
    }
  },
  components: {
    yandexMap,
    ymapMarker,
    Form
  },
  computed: {
    balloonTemplate() {
      return `
      <p style="font-size: 1rem;
        font-weight: 600;
        text-align: center;">ЦОД КОМПЛИТ</p>
        <p style="font-size: 1rem;
        font-weight: 400;
        text-align: center;">Яхтенная улица, 22к1</p>
    `
    }
  },
}
</script>


<style lang="scss" scoped>
.link-file {
  display: block;
  text-decoration: none;
  text-align: center;
  color: #1a6bb2;
}

#contacts {
  position: relative;
  padding-top: 100px;

  @media screen and (min-width: 0) and (max-width: 600px) {
    padding-top: 50px;
  }

  @media screen and (min-width: 601px) and (max-width: 900px) {
    padding-top: 70px;
  }

  .fluid-header {
    position: absolute;
    display: flex;
    align-items: stretch;

    .taper {
      height: 1em;
      background: url('/img/contacts-tape-image.jpg') repeat-x;
      background-size: auto 75%;
    }

    .taper.left {
      flex-grow: 1;
      background-position: right 55%;
    }

    .taper.right {
      flex-grow: 1;
      background-position: left 55%;
    }

    >h1 {
      width: auto;
      height: 1em;
      line-height: 1em;
      margin: 0;
      vertical-align: center;
    }

  }

  .tape-wrapper,
  .fluid-header>* {
    font-size: 3em;

    @media screen and (min-width: 0) and (max-width: 600px) {
      margin: auto;
      font-size: 1em;
    }

    @media screen and (min-width: 601px) and (max-width: 900px) {
      font-size: 2rem;
    }
  }

  .tape-wrapper {
    display: block;
    position: static;
    height: 1em !important;
  }

  >div.container {
    padding-top: 100px;

    @media screen and (min-width: 0) and (max-width: 600px) {
      padding-top: 50px;
    }

    @media screen and (min-width: 601px) and (max-width: 900px) {
      padding-top: 70px;
    }
  }
}

.contacts__address {
  padding-bottom: 20px;
}

.contacts__address>* {
  padding: 10px;
  text-transform: uppercase;
}

.contacts__address>h2 {
  font-weight: 600;

  @media screen and (min-width: 0) and (max-width: 600px) {
    margin: auto;
    font-size: 1em;
  }

  @media screen and (min-width: 601px) and (max-width: 900px) {
    font-size: 1rem;
  }
}

.contacts__address>p {
  font-size: 25px;
  text-transform: uppercase;

  @media screen and (min-width: 0) and (max-width: 600px) {
    margin: auto;
    font-size: 1rem;
  }

  @media screen and (min-width: 601px) and (max-width: 900px) {
    font-size: 1rem;
  }
}
</style>
