<template>
    <div class="inner-list">
        <p>Внедрение, доработка и оптимизация MDM систем:</p>
        <div class="special-marker__row">
            <div class="special-marker__row_list special-marker__row_first">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker__row_text">
                ведение классификаторов всех видов НСИ
            </div>
        </div>
        <div class="special-marker__row">
            <div class="special-marker__row_list ">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker__row_text">
                автоматизированный сбор, обработка, первичная нормализация данных
            </div>
        </div>
        <div class="special-marker__row">
            <div class="special-marker__row_list ">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker__row_text">
                автонаименование, автоклассификация, удаление дублей, связь с аналогами
            </div>
        </div>
        <div class="special-marker__row">
            <div class="special-marker__row_list ">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker__row_text">
                автоматизация процесса управления НСИ
            </div>
        </div>
        <div class="special-marker__row">
            <div class="special-marker__row_list ">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker__row_text">
                интеграция с универсальной шиной (ESB)
            </div>
        </div>
        <div class="special-marker__row">
            <div class="special-marker__row_list special-marker__row_last">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker__row_text">
                система отчетности
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "IntegrationMDM"
    }
</script>

<style scoped>

</style>