<template>
    <div class="inner-list">
        <p>Ситуационный центр – это информационная система, собирающая данные от прочих ИС (ИТ, инженерные SCADA системы, СКУД, системы видеонаблюдения, IoT) и непосредственно с электронных компонент и оборудования по ряду протоколов. Система реализует возможности:</p>
        <div class="special-marker__row">
            <div class="special-marker__row_list special-marker__row_first">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker__row_text">
                централизованного управления и мониторинга всех систем задний/сооружений/комплексов, реализуя - концепцию &laquo;умных&raquo; зданий
            </div>
        </div>
        <div class="special-marker__row">
            <div class="special-marker__row_list ">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker__row_text">
                единую централизованную диспетчеризацию и управления работами с возможностью выводом на мобильные устройства
            </div>
        </div>
        <div class="special-marker__row">
            <div class="special-marker__row_list special-marker__row_last">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker__row_text">
                использования ИИ и систем машинного обучения для предиктивной аналитики и &laquo;умного&raquo; управления реакцией на события.
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SingleCentre"
    }
</script>

<style scoped>

</style>