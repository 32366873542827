<template>
    <div class="inner-list">
        <p>
            Мобильное приложения для iOS/Android: карты, навигатор, каталог объектов (POI), прочий контент, получение push-уведомлений, отслеживание кликов, маршрутов
        </p>
    </div>
</template>

<script>
    export default {
        name: "MobileApp"
    }
</script>

<style scoped>

</style>