<template>
    <div class="inner-list">
        <p>Внедрение систем мобильного позиционирования:</p>
        <div class="special-marker__row">
            <div class="special-marker__row_list special-marker__row_first">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker__row_text">
                биконы (BLE) - позиционирование с использованием сматрфонов
            </div>
        </div>
        <div class="special-marker__row">
            <div class="special-marker__row_list ">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker__row_text">
                умные карты доступа с поддержкой BLE (позиционирование по wi-fi точкам доступа с поддержкой BLE
            </div>
        </div>
        <div class="special-marker__row">
            <div class="special-marker__row_list ">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker__row_text">
                сервис-портал (загрузка карт, определение POI, маршрутов, настройка оповещений и пр.)
            </div>
        </div>
        <div class="special-marker__row">
            <div class="special-marker__row_list ">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker__row_text">
                Push-сервер
            </div>
        </div>
        <div class="special-marker__row">
            <div class="special-marker__row_list ">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker__row_text">
                отчетность: предпочтения, маршруты, Heat Map
            </div>
        </div>
        <div class="special-marker__row">
            <div class="special-marker__row_list special-marker__row_last">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker__row_text">
                безопасность: идентификация, отслеживание занятости пространств, маршруты эвакуации
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Mobile"
    }
</script>

<style scoped>

</style>