<template>
    <div class="advantages" id="advantages">
        <h2 class="section-title">Преимущества</h2>
        <div class="container advantages__content main-wrap">
            <div class="advantages__content_desktop">
                <div class="bg-img">
                    <div class="positioning-text statuses">
                        <h5>Наивысшие партнерские статусы</h5>
                        <p>Налаженные партнерские отношения с лидерами рынка позволяют предлагать заказчику качественные программные решения на выгодных условиях</p>
                    </div>
                    <div class="positioning-text employee">
                        <h5>Высококвалифицированные сотрудники</h5>
                        <p>Специалисты компании регулярно повышают профессиональную квалификацию в специализированных
                            учебных центрах России и зарубежья, что подтверждено сертификатами наивысших степеней</p>
                    </div>
                    <div class="positioning-text demo-center">
                        <h5>Собственный демоцентр</h5>
                        <p>Позволяет моделировать, тестировать и оценивать информационные решения любой сложности</p>
                    </div>
                    <div class="positioning-text data-center">
                        <h5>Собственный ЦОД</h5>
                        <p>Соответствует уровню Tier III по надежности и безопасности, предоставляются SaaS услуги</p>
                    </div>
                    <div class="positioning-text call-center">
                        <h5>Круглосуточный колл-центр</h5>
                        <p>Поддержка заказчиков в режиме 24/7</p>
                    </div>
                </div>
            </div>
            <div class="advantages__content_mobile">
                <div class="advantages__row">
                    <div class="advantages__row_lines">
                        <img src="/img/demo-center.png" alt="">
                    </div>
                    <div class="advantages__row_desc">
                        <h5>Собственный демоцентр</h5>
                        <p>Позволяет моделировать, тестировать и оценивать информационные решения любой сложности</p>
                    </div>
                </div>
                <div class="advantages__row">
                    <div class="advantages__row_lines">
                        <img src="/img/data-center.png" alt="">
                    </div>
                    <div class="advantages__row_desc">
                        <h5>Собственный ЦОД</h5>
                        <p>Соответствует уровню Tier III по надежности и безопасности, предоставляются SaaS услуги</p>
                    </div>
                </div>
                <div class="advantages__row">
                <div class="advantages__row_lines">
                  <img src="/img/call-center.png" alt="">
                </div>
                <div class="advantages__row_desc">
                  <h5>Круглосуточный колл-центр</h5>
                  <p>Поддержка заказчиков в режиме 24/7</p>
                </div>
              </div>
                <div class="advantages__row">
                <div class="advantages__row_lines">
                  <img src="/img/employee.png" alt="">
                </div>
                <div class="advantages__row_desc">
                  <h5>Высококвалифицированные сотрудники</h5>
                  <p>Специалисты компании регулярно повышают профессиональную квалификацию в специализированных учебных центрах России и зарубежья, что подтверждено сертификатами наивысших степеней</p>
                </div>
              </div>
                <div class="advantages__row">
                <div class="advantages__row_lines">
                  <img src="/img/statuses.png" alt="">
                </div>
                <div class="advantages__row_desc">
                  <h5>Наивысшие партнерские статусы</h5>
                  <p>Налаженные партнерские отношения с лидерами рынка позволяют предлагать заказчику качественные программные решения на выгодных условиях</p>
                </div>
              </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "Advantages"
    }
</script>

<style lang="scss" scoped>

</style>
