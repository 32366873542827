<template>
    <div class="inner-list">
        <p>Разработка систем классификации, создание классов и шаблонов классов: перечень свойств и характеристик класса.</p>
        <p>Консолидация и нормализация данных (удаление дублей, выделение аналогов, создание эталонных элементов НСИ (наименование, классификация, характеристики и свойства, НТД, нормализация ссылок), поддержание НСИ в актуальном состоянии.</p>
        <p>Наши специалисты имеют более чем 10-летний опыт с различными отраслевыми данными.</p>
    </div>
</template>

<script>
    export default {
        name: "MasterData"
    }
</script>

<style scoped>

</style>