<template>
    <div id="app">
        <router-view class="view" />
        <CookieNotice />
    </div>
</template>
<script>
import CookieNotice from '@/cookie/CookieNotice.vue';

export default {
    name: 'App',
    components: {
        CookieNotice,
    },
};
</script>
<style lang="scss">
@import './styles/index.scss';
</style>
