<template>
    <div class="inner-list">
        <p>Автоматизация процесса учета, обслуживания и ремонтов оборудования, являющимся активами компании. Интеграция с системами АСУ ТП, инженерными (SCADA) системами.</p>
    </div>
</template>

<script>
    export default {
        name: "Maintanence"
    }
</script>

<style scoped>

</style>