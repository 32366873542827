<template>
    <div class="inner-list">
        <p>Анализ степени автоматизации типовых операции на предприятии - выявление предмета автоматизации, подбор решения. Расчет ТСО и выгод внедрения. Пример: автоответ на входящую почту и маршрутизация писем, вложений, заявок на менеджеров в систему документооборота, CRM</p>
        <div class="special-marker__row">
            <div class="special-marker__row_list special-marker__row_first">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker__row_text">
                Разработка скриптов, настройка маршрутов
            </div>
        </div>
        <div class="special-marker__row">
            <div class="special-marker__row_list special-marker__row_last">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker__row_text">
                Интеграция с прочими ИС
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Routine"
    }
</script>

<style scoped>

</style>