<template>
    <div class="manager-system">
        <div class="manager-system__desc">
            <div class="manager-system__left">
                <img class="manager-system__img" src="/img/mobile_assistant.svg" alt="">
            </div>
            <div class="manager-system__righ">
                <p>
                    Интернет вещей – неотъемлемая часть нашей реальности. Он неразрывно связан с понятиеми: удобство получения информации, безопасность, изучение пользовательского опыта (customer experience).
                </p>
                <p>
                    Решение, разработанное специалистами Комплит ИС на базе ПО open source, позволяет создать систему, совмещающую в себе возможности: навигации как внутри помещений, так и снаружи, предоставление персонализированного контента, изучение предпочтений и поведения пользователей, повышения уровня безопасности.
                </p>
                <p>
                    Решение будет интересно для торгово-развлекательных центров, музеев и выставок, вузов.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Indoor"
    }
</script>

<style scoped lang="scss">
    .manager-system {
        margin: 2rem 0;

        .manager-system__desc {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-evenly;

            .manager-system__left {
                max-width: calc(100% / 12 * 6);
                padding: 0 15px;

                .manager-system__img {
                    max-width: 100%;
                    width: 600px;
                    height: auto;
                }
            }

            .manager-system__righ {
                max-width: calc(100% / 12 * 6);
                padding: 0 15px;
            }

            @media screen and (max-width: 867px) {
                .manager-system__left {
                    max-width: 100%;
                    margin: 10px 0;
                }

                .manager-system__righ {
                    max-width: 100%;
                    margin: 10px 0;

                    @media screen and (max-width: 320px) {
                        font-size: 0.8em;
                    }
                }
            }
        }

        .manager-system__text {
            text-align: center;
            margin: 2rem 0;

            @media screen and (max-width: 768px) {
                margin: 0;
            }
        }
    }

</style>