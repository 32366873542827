<template>
    <div class="inner-list">
        <p>
            Разработка решений на платформе 1С по оперативному управлению предприятием, автоматизации организационной и хозяйственной деятельности. Решение задач управленческого учета, планирования, бюджетирования и финансового анализа любой сложности.
        </p>
    </div>
</template>

<script>
    export default {
        name: "CustomC"
    }
</script>

<style scoped>

</style>