<template>
    <div class="container">
        <p class="text-center m-auto form-header">
            Если у вас появились вопросы, просто укажите свои контактные данные
            в форме ниже.<br />
            Наш менеджер свяжется с вами в ближайшее время.
        </p>
        <div class="form-wrap mb-5">
            <form id="cis-form" @submit.prevent="handleSubmit">
                <!-- Recaptcha -->
                <!-- <vue-recaptcha
                    ref="recaptcha"
                    size="invisible"
                    :sitekey="sitekey"
                    @verify="contact"
                    @expired="onCaptchaExpired"
                /> -->

                <!-- Message -->
                <div class="form-group">
                    <div class="form-floating">
                        <label for="message"></label>
                        <textarea
                            class="form-control"
                            placeholder="Напишите нам"
                            id="message"
                            v-model="form.message"
                            :class="{
                                'is-invalid':
                                    submitted && $v.form.message.$error,
                            }"
                            style="height: 150px;"
                        ></textarea>
                        <div
                            v-if="submitted && !$v.form.message.required"
                            class="invalid-feedback"
                        >
                            Пожалуйста, введите текст сообщения
                        </div>
                    </div>
                </div>

                <!-- row -->
                <div class="row">
                    <div class="col-md-6">
                        <!-- Name -->
                        <div class="form-group">
                            <label for="name"></label>
                            <input
                                placeholder="Имя"
                                type="text"
                                v-model="form.name"
                                id="name"
                                name="name"
                                class="form-control"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.name.$error,
                                }"
                            />
                            <div
                                v-if="submitted && !$v.form.name.required"
                                class="invalid-feedback"
                            >
                                Пожалуйста, введите ваше имя
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <!-- Company name -->
                        <div class="form-group">
                            <label for="companyName"></label>
                            <input
                                placeholder="Компания"
                                type="text"
                                v-model="form.companyName"
                                id="companyName"
                                name="companyName"
                                class="form-control"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.companyName.$error,
                                }"
                            />
                            <div
                                v-if="
                                    submitted && !$v.form.companyName.required
                                "
                                class="invalid-feedback"
                            >
                                Пожалуйста, введите название компании
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <!-- Phone -->
                        <div class="form-group">
                            <label for="phone"></label>
                            <the-mask
                                mask="+7 (###) ###-####"
                                value=""
                                type="tel"
                                :masked="true"
                                placeholder="+7 (___) ___-____"
                                v-model.trim="$v.form.phone.$model"
                                @change="$v.form.phone.$touch()"
                                class="form-control"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.phone.$error,
                                }"
                            />
                            <div
                                v-if="submitted && $v.form.phone.$error"
                                class="invalid-feedback"
                            >
                                <span
                                    class="error"
                                    v-if="!$v.form.phone.required"
                                    >Пожалуйста, введите номер телефона</span
                                >
                                <span
                                    class="error"
                                    v-if="!$v.form.phone.phoneNumber"
                                    >Номер телефона введен неверно</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <!-- Email -->
                        <div class="form-group">
                            <label for="email"></label>
                            <input
                                placeholder="E-mail"
                                type="email"
                                v-model="form.email"
                                id="email"
                                name="email"
                                class="form-control"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.email.$error,
                                }"
                            />
                            <div
                                v-if="submitted && $v.form.email.$error"
                                class="invalid-feedback"
                            >
                                <span v-if="!$v.form.email.required"
                                    >Пожалуйста, заполните поле Email</span
                                >
                                <span v-if="!$v.form.email.email"
                                    >Email введен неверно</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /.row -->

                <!-- row -->
                <div class="row align-items-center">
                    <div class="col-md-9">
                        <!-- Checkbox -->
                        <div class="form-check form-check-inline my-3">
                            <div class="row">
                                <div class="col-md-5">
                                    <input
                                        type="checkbox"
                                        id="flexCheckDefault"
                                        v-model="form.flexCheckDefault"
                                        class="form-check-input"
                                    />
                                    <label
                                        class="form-check-label"
                                        for="flexCheckDefault"
                                    >
                                        Согласен на обработку персональных
                                        данных
                                    </label>
                                </div>
                                <div class="col-md-7">
                                    <div class="custom-text">
                                        Отправляя форму обратной связи,
                                        <a
                                            class="custom-link"
                                            href="/docs/agreement.pdf"
                                            target="_blank"
                                            >вы соглашаетесь</a
                                        >
                                        с условиями
                                        <a
                                            class="custom-link"
                                            href="/docs/Политика конфиденциальности КИС.pdf"
                                            target="_blank"
                                            >обработки и использования
                                            персональных данных</a
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <!-- Submit button -->
                        <div class="form-group button-box">
                            <button class="btn button-custom my-3">
                                Отправить
                            </button>
                        </div>
                    </div>
                </div>
                <!-- /.row -->
            </form>
        </div>
        <!-- /.form-wrap -->
    </div>
    <!-- /.container -->
</template>

<script>
/**
 * Всё что закомментировано оставлено на всякий случай для того,
 * чтобы вспомнить ход мыслей, если вдруг потребуется.
 */

import { validationMixin } from 'vuelidate';
import { required, email, helpers } from 'vuelidate/lib/validators';
import { TheMask } from 'vue-the-mask';
// import VueRecaptcha from 'vue-recaptcha';
// import axios from 'axios';

const phoneNumber = helpers.regex(
    'phoneNumber',
    /^\+\s*7\s*\(\s*[0-9]\d{2}\)\s*\d{3}\s*-\s*\d{4}$/,
);

export default {
    name: 'contactForm',
    mixins: [validationMixin],
    components: {
        TheMask,
        // VueRecaptcha
    },
    data() {
        return {
            form: {
                message: '',
                name: '',
                companyName: '',
                email: '',
                phone: '',
                flexCheckDefault: false,
            },
            submitted: false,
            formInstance: null,
            sitekey: '6LeLhY0nAAAAAHbJ4u957EXFmYq41wx1lxYSNO_N',
        };
    },
    computed: {
        phoneNumber: {
            get() {
                return this.form.phone;
            },
            set(value) {
                this.form.phone = value;
            },
        },
    },
    validations: {
        form: {
            message: { required },
            name: { required },
            companyName: { required },
            email: { required, email },
            phone: { required, phoneNumber },
        },
    },
    methods: {
        /* handleSubmit() {
            this.submitted = true;
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            } else {
                this.submitted = true;
            }

            this.$refs.recaptcha.execute();
        }, */
        handleSubmit() {
            this.submitted = true;
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            } else {
                this.$submitter.submit(this.form).then((result) => {
                    console.log(result);
                    if (result.success) {
                        this.notify(
                            'Обращение успешно создано. Спасибо!',
                            'success',
                            3000,
                        );
                        this.submitted = true;
                    } else {
                        this.notify(
                            'Oops! Что-то пошло не так. Свяжитесь с нами. webdevelopment@tillypad.ru',
                            'error',
                            10000,
                        );
                        return;
                    }
                });
            }
        },
        /* async contact(recaptchaToken) {
            const {status, data} = await axios.post('http://complete-is.server/api/capcheck', {
                recaptchaToken: recaptchaToken
            });
            if (status === 200) {
                if (data.status === "success") {
                    await this.sendAppeal()
                }
            } else {
                this.$refs.recaptcha.reset()
            }
        }, */
        /* async sendAppeal() {
            const { status, data } = await axios.post(
                'http://complete-is.server/api/appeal',
                {
                    formData: this.form,
                },
            );
            if (status === 200) {
                console.log(data);
                this.notify(
                    'Обращение успешно создано. Спасибо!',
                    'success',
                    3000,
                );
            } else {
                this.notify('Что-то пошло не так.', 'error', 3000);
            }
        }, */
        /* onCaptchaExpired() {
            this.$refs.recaptcha.reset();
        }, */
        notify(message, type, timeout = 1000) {
            const app = document.querySelector('#app');
            let notifyBox = document.createElement('div');

            notifyBox.innerText = message;

            if (type === 'success') {
                notifyBox.setAttribute('style', 'background-color: green;');
            }

            if (type === 'error') {
                notifyBox.setAttribute('style', 'background-color: red;');
            }

            notifyBox.style.color = '#ffffff';
            notifyBox.style.position = 'fixed';
            notifyBox.style.bottom = '50px';
            notifyBox.style.width = '80%';
            notifyBox.style.padding = '10px';
            notifyBox.style['border-radius'] = '5px';
            notifyBox.style.right = 'auto';
            notifyBox.style.left = '10%';
            notifyBox.style.zIndex = '99';

            app.append(notifyBox);

            setTimeout(() => {
                notifyBox.remove();
            }, timeout);
        },
    },
};
</script>

<style lang="scss" scoped>
.form-header {
    padding: 35px 10%;
    font-weight: 600;
}

.form-wrap {
    @media screen and (max-width: 991px) {
        margin-right: auto;
        margin-left: auto;
        max-width: 500px;
    }
}

.button-box {
    text-align: end;
}

.button-custom {
    background-color: #1a6bb2;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    padding: 25px 15px;
    text-transform: uppercase;
    border-radius: 10px;
    transition: 0.2s;

    &:hover {
        background-color: #114069;
    }
}

.custom-link {
    color: #212529;
}

.custom-text {
    font-size: 14px;
}

#contactForm {
    padding-bottom: 75px;
}
</style>
