<template>
    <div class="inner-list">
        <div class="special-marker__row">
            <div class="special-marker__row_list special-marker__row_first">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker__row_text">
                Автоматизация процесса тестирования (функциональное, unit, нагрузочное и т.п.)
            </div>
        </div>
        <div class="special-marker__row">
            <div class="special-marker__row_list ">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker__row_text">
                Автоматизация тестирования на мобильных устройствах
            </div>
        </div>
        <div class="special-marker__row">
            <div class="special-marker__row_list special-marker__row_last">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker__row_text">
                Интеграция с системами разработки, непрерывной интеграции.
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Testing"
    }
</script>

<style scoped>

</style>