<template>
  <div>
    <div class="top-header">
      <div class="container">
        <div class="row align-items-center justify-content-between">

          <div class="col-md-4 mail-desktop">
            <a class="top-header__mail" :href="mail.link">
              <span>{{ mail.name }}</span>
            </a>
          </div>
          <!-- /.col-md-4 .mail-desktop -->

          <div class="col-md-4 col-6 logo-desktop">
            <a class="top-header__logo" href="">
              <img :src="img.logo" alt="">
            </a>
          </div>
          <!-- ./col-md-4 .col-6 .logo-desktop -->

          <div class="col-md-4 col-6 phone-desktop">
            <a class="top-header__phone" :href="phone.link">
              <span>{{ phone.name }}</span>
            </a>
            <!-- /.top-header__phone -->

            <div class="mobile">

              <a :href="mail.link" class="mobile__icon">
                <img :src="img.mail" alt="">
              </a>
              <!-- /.mobile__icon -->

              <a :href="phone.link" class="mobile__icon">
                <img :src="img.phone" alt="">
              </a>
              <!-- /.mobile__icon -->

              <div class="menu-toggle" @click="ShowFunc">
                <span></span>
              </div>
              <!-- /.mobile__icon -->

            </div>
            <!-- /.mobile -->

          </div>
          <!-- ./col-md-4 .col-6 .phone-desktop -->
        </div>

      </div>
      <!-- /.container -->

    </div>
    <BottomHeader :class="{ bHeader: isActive }"/>
  </div>

</template>

<script>

import BottomHeader from "@/vue-components/Layout/LayoutHead/BottomHeader";

export default {
  components: {
    BottomHeader
  },

  methods: {
    ShowFunc: function () {
      this.isActive = !this.isActive
    }
  },

  data() {
    return {
      isActive: false,
      mail: {
        link: 'mailto:info-is@complete.ru',
        name: 'info-is@complete.ru'
      },
      phone: {
        link: 'tel:88123869003',
        name: '8 812 386 90 03'
      },
      img: {
        logo: '/img/logo.png',
        mail: '/img/mail.png',
        phone: '/img/phone.png'

      },
      menu: [
        {
          id: 1,
          name: 'о компании',
        },
        {
          id: 2,
          name: 'преимущества',
        },
        {
          id: 3,
          name: 'услуги',
        },
        {
          id: 4,
          name: 'контакты',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>

$width: 40px;
$height: 40px;

$widthMobil: 40px;
$heightMobile: 40px;


.top-header {
  background-color: #1a3842;
  color: #ffffff;
  font-family: 'Formular Light', sans-serif;
  padding: 25px 0;

  .mail-desktop,
  .logo-desktop,
  .phone-desktop {
    text-align: center;

    .top-header__logo {
      img {
        max-width: 151px;
      }
    }

    @media screen and (max-width: 445px) {
      .top-header__logo {
        svg {
          max-width: 130px;
        }
      }
    }

    @media screen and (max-width: 320px) {
      .top-header__logo {
        svg {
          max-width: 100px;
        }
      }
    }


    .mobile {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      align-items: center;

      .mobile__icon img {
        display: none;
        max-width: 45px;
      }

      .menu-toggle {
        width: 45px;
        height: 45px;
        border: 3px solid #fff;
        border-radius: 100px;
        position: relative;
        margin-left: 20px;
        display: none;

        &::before, &::after, span {
          content: '';
          width: 70%;
          top: 30%;
          right: 15%;
          border-bottom: 3px solid #ffffff;
          position: absolute;
        }

        &::before {
          content: '';
          width: 90%;
          top: 48%;
          left: 5%;
          border-bottom: 3px solid #ffffff;
          position: absolute;
        }

        &::after {
          content: '';
          width: 70%;
          bottom: 25%;
          border-bottom: 3px solid #ffffff;
          position: absolute;
        }
      }

      @media screen and (max-width: 430px) {
        .mobile__icon img {
          width: 35px;
        }

        .menu-toggle {
          width: 35px;
          height: 35px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .top-header__phone {
      display: none;
    }

    .mail-desktop {
      display: none;
    }

    .logo-desktop {
      text-align: left;
    }

    .phone-desktop {
      text-align: right;

      .mobile {
        .mobile__icon img {
          display: block;
          margin: 0 0.5rem;
        }

        .menu-toggle {
          display: block;
        }
      }
    }
  }


  @media screen and (max-width: 365px) {
    .phone-desktop {
      text-align: right;

      .mobile {
        .mobile__icon {
          display: block;
          margin: 0 0.3rem;
        }
      }
    }
  }

  @media screen and (max-width: 364px) {
    .phone-desktop {
      text-align: right;

      .mobile {
        .mobile__icon img {
          display: block;
          margin: 0;
        }

        .menu-toggle {
          margin-left: 5px;
        }
      }
    }
  }
}

.top-header__mail, .top-header__phone {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.2rem;
}

.top-header__phone {
  font-family: 'Formular Medium', serif;
}

</style>
