<template>
    <div class="inner-list">
        <p>Данный процесс обеспечивает возможность планирования, учёта и отслеживания состояния программного обеспечения и лицензий на ПО. Программная среда позволяет автоматически обнаруживать установки ПО, соотносить с имеющимися лицензиями, формировать отчеты об использовании, покрытия лицензиями, предоставляя возможности по перераспределению и/или экономии лицензий.</p>
    </div>
</template>

<script>
    export default {
        name: "AssetManager"
    }
</script>

<style scoped>

</style>