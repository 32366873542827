<template>
    <div class="inner-list">
        <p>Построение ресурсно-сервисной модели ИТ инфраструктуры и ИТ услуг предприятия, выделение структурированного каталога ИТ услуг для бизнес-подразделений, разработка системы SLA (системы показателей качества предоставления ИТ услуги), разработка методик автоматизированного расчета доступности ИТ услуг. Автоматизация работы с Каталогом ИТ услуг с помощью портала самообслуживания (ESS).</p>
    </div>
</template>

<script>
    export default {
        name: "Catalog"
    }
</script>

<style scoped>

</style>