<template>
    <div class="inner-list">
        <p>
            Автоматизация ведения электронного документооборота компании на базе решений на платформе 1С. Учет входящей и исходящей корреспонденции, учет обращений граждан, внутренний документооборот, работа с договорными документами, управление процессами, электронная подпись документов, контроль исполнения поручений, учет и планирование рабочего времени, коллективные обсуждения и голосования на форуме, делегирование полномочий, интеграция с решениями на платформе 1С.
        </p>
    </div>
</template>

<script>
    export default {
        name: "DocFlow"
    }
</script>

<style scoped>

</style>