<template>

  <BaseLayout>
    <!-- Header start -->
    <template v-slot:header>
      <LayoutHead></LayoutHead>
    </template>
    <!-- Header end -->

    <!-- Main start -->
    <TopSlider></TopSlider>
<!--    <Slider></Slider>-->
    <About></About>
    <Geography></Geography>
    <Advantages></Advantages>
    <ServiceManagement></ServiceManagement>
    <Direction></Direction>
    <Itsolutions></Itsolutions>
    <Contacts></Contacts>
    <!-- Main end -->

    <!-- Footer start -->
    <template v-slot:footer>
      <LayoutFoot></LayoutFoot>
    </template>
    <!-- Header end -->
  </BaseLayout>

</template>
<script>

// import Slider from "@/pages/Home/Slider";
import TopSlider from "./TopSlider";
import About from "@/pages/Home/AboutSection/About";
import Geography from "@/pages/Home/Geography/Geography";
import Advantages from "@/pages/Home/Advantages";
import ServiceManagement from "@/pages/Home/ServiceManagement";
import Direction from "@/pages/Home/Direction";
import Itsolutions from "@/pages/Home/Itsolutions";
import Contacts from "@/pages/Home/Contacts/Contacts";

export default {
  data() {
    return {}
  },
  components: {
    TopSlider,
    // Slider,
    About,
    Geography,
    Advantages,
    ServiceManagement,
    Direction,
    Itsolutions,
    Contacts
  }
}

</script>
<style lang="scss" scoped>

</style>
