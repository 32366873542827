<template>
    <div class="inner-list">
        <p>Автоматизированная система управления контентом (с интеграцией со стандартами системами панелей, системой управления знанием, кампусом, контентными системами, сайтами):</p>
        <div class="special-marker__row">
            <div class="special-marker__row_list special-marker__row_first">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker__row_text">
                визуализация контента в местах установки
            </div>
        </div>
        <div class="special-marker__row">
            <div class="special-marker__row_list ">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker__row_text">
                настройка зависимости выводимого контента: расписание, по данным систем позиционирования или видеоаналитики, систем присутствия
            </div>
        </div>
        <div class="special-marker__row">
            <div class="special-marker__row_list special-marker__row_last">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker__row_text">
                единый центр управления
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "VideoPanels"
    }
</script>

<style scoped>

</style>