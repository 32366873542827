<template>
  <div class="header-wrapper">
    <TopHeader></TopHeader>
  </div>
</template>
<script>

import TopHeader from "@/vue-components/Layout/LayoutHead/TopHeader";

export default {
  components: {
    TopHeader,
  }
}
</script>
