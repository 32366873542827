<template>
    <div class="inner-list mt-5">
        <div class="special-marker__row">
            <div class="special-marker-top__row_list special-marker-top__row_first">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker-top__row_text">
                <p><strong>Автоматизация управления требованиями бизнеса:</strong></p>
                <ul>
                    <li>воронка требований</li>
                    <li>управление процессом сбора и реализации бизнес-требований</li>
                </ul>
            </div>
        </div>
        <div class="special-marker-top__row">
            <div class="special-marker-top__row_list ">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker-top__row_text">
                <p><strong>Автоматизация управления портфелями и программами:</strong></p>
                <ul>
                    <li>бюджетирование</li>
                    <li>бизнес-цели и риски</li>
                    <li>анализ "что-если", план-факт анализ</li>
                </ul>
            </div>
        </div>
        <div class="special-marker-top__row">
            <div class="special-marker-top__row_list ">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker-top__row_text">
                <p><strong>Автоматизация управления проектами:</strong></p>
                <ul>
                    <li>планирование задач, бюджета, целей и рисков проекта</li>
                    <li>анализ "что-если", план-факт анализ</li>
                </ul>
            </div>
        </div>
        <div class="special-marker-top__row">
            <div class="special-marker-top__row_list special-marker-top__row_last">
                <div class="vert"></div>
                <div class="horiz"></div>
                <div class="point"></div>
            </div>
            <div class="special-marker-top__row_text">
                <p><strong>Автоматизация управления ресурсами проекта:</strong></p>
                <ul>
                    <li>ресурсные пулы (квалификация, роль, предметная область)</li>
                    <li>time-management, табели</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AutomationManagement"
    }
</script>

<style scoped>

</style>