<template>
    <div class="inner-list">
        <p>
            Автоматизация ведения электронных медицинских карт, учет оказанных медицинских услуг, предварительная запись пациентов на прием, консультации, исследования, манипуляции. Оперативное планирование деятельности медицинских кабинетов. Интеграция с медицинским оборудованием.
        </p>
    </div>
</template>

<script>
    export default {
        name: "MedicineAutomation"
    }
</script>

<style scoped>

</style>