import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import YmapPlugin from 'vue-yandex-maps';
import VueScrollTo from 'vue-scrollto';
import Submitter from './submitter';

import './vue-components';

Vue.config.productionTip = false;
const settings = {
    apiKey: 'd287e75e-a1fc-4b81-bf13-b2ad221e6a72',
    lang: 'ru_RU',
    coordorder: 'latlong',
    version: '2.1',
};
Vue.use(YmapPlugin, settings);
Vue.use(VueScrollTo);
Vue.prototype.$submitter = new Submitter();
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
