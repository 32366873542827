<template>
    <div class="cookie-notice" v-if="!storeCookie">
        <p>
            Продолжая использовать сайт <strong>complete-is.ru</strong> вы
            соглашаетесь с использованием файлов cookie.
        </p>
        <p>
            Более подробная информация на странице
            <a href="/docs/Политика конфиденциальности КИС.pdf" target="_blank"
                >Политика конфиденциальности.</a
            >
        </p>
        <div class="cookie__button-box">
            <button class="cookie__button" type="button" @click="setCookie">
                Спасибо, понятно
            </button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CookieNotice',
    data() {
        return {};
    },
    computed: {
        storeCookie() {
            return this.$store.state.cookieBanner;
        },
    },
    methods: {
        setCookieNotice(path, name, value, options = {}) {
            options = {
                path: path,
                ...options,
            };

            if (options.expires instanceof Date) {
                options.expires = options.expires.toUTCString();
            }

            let updatedCookie =
                encodeURIComponent(name) + '=' + encodeURIComponent(value);

            for (let optionKey in options) {
                updatedCookie += '; ' + optionKey;
                let optionValue = options[optionKey];
                if (optionValue !== true) {
                    updatedCookie += '=' + optionValue;
                }
            }

            document.cookie = updatedCookie;
        },
        getCookie(name) {
            let matches = document.cookie.match(
                new RegExp(
                    '(?:^|; )' +
                        name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') +
                        '=([^;]*)',
                ),
            );
            return matches ? decodeURIComponent(matches[1]) : undefined;
        },
        setCookie() {
            this.setCookieNotice('/', 'notice', true, { 'max-age': 31536000 });
            this.$store.commit('setCookieFromBanner', true);
        },
    },
    beforeMount() {
        this.$store.commit('setCookieFromBanner', this.getCookie('notice'));
    },
};
</script>
<style scoped lang="scss">
.cookie-notice {
    background-color: #fafafa;
    border: 1px solid #dcdcdc;
    border-radius: 15px;
    position: fixed;
    bottom: 20px;
    padding: 15px;
    left: 5%;
    right: 5%;
    max-width: 600px;
    z-index: 9999;
    box-shadow: 0 3px 19px #0000001c;
    transition: 1s;

    .cookie__button-box {
        display: flex;
        justify-content: center;

        .cookie__button {
            border: none;
            background-color: #5cb85c;
            color: #ffffff;
            border-radius: 15px;
            padding: 5px 10px;
            transition: 0.2s;

            &:hover {
                background-color: #316f31;
            }
        }
    }
}
</style>
