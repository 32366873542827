export default class Submitter {
    constructor() {
        this.siteKey = '6LeLhY0nAAAAAHbJ4u957EXFmYq41wx1lxYSNO_N';
        this.ready = 0;

        this.loadRecaptcha().then((grecaptcha) => {
            this.grecaptcha = grecaptcha;
            this.explicitRender().then((error) => {
                if (!error) this.ready = 1;
            });
        });
    }

    waitGrecaptcha(callback) {
        if (window.grecaptcha === undefined) {
            setTimeout(() => {
                this.waitGrecaptcha(callback);
            }, 100);
        } else {
            window.grecaptcha.ready(() => {
                callback(window.grecaptcha);
            });
        }
    }

    loadRecaptcha() {
        const scriptBase = 'https://www.google.com/recaptcha/api.js';
        const scriptElement = document.createElement('script');

        scriptElement.setAttribute('recaptcha-v3-script', '');
        scriptElement.src = scriptBase + '?render=explicit';

        return new Promise((resolve, reject) => {
            scriptElement.addEventListener(
                'load',
                this.waitGrecaptcha((grecaptcha) => {
                    resolve(grecaptcha);
                }),
                false,
            );

            scriptElement.onError = (error) => reject(error);

            document.head.appendChild(scriptElement);
        });
    }

    explicitRender() {
        const renderParameters = {
            sitekey: this.siteKey,
        };

        return new Promise((resolve) => {
            let error;
            if (!this.grecaptcha) error = 'err_no_instance';
            else error = this.grecaptcha.render(renderParameters);
            resolve(error);
        });
    }

    retrieveToken() {
        return new Promise((resolve, reject) => {
            this.grecaptcha.ready(function() {
                this.grecaptcha.execute(this.siteKey).then(function(token) {
                    if (!token) reject('error while getting token');
                    else resolve(token);
                });
            });
        });
    }

    submit(formData) {
        if (!this.ready) {
            // todo: опопвестить юзера, что отправка невозможна! попросить связаться с нами.
        }

        let result = {
            success: false,
            errors: [],
        };

        return new Promise((resolve) => {
            // иметь ввиду, что если промис не отработает, то скрипт никогда не исполнится (мб добавить проверки).
            this.retrieveToken().then(
                (token) => {
                    const data = {
                        form_data: JSON.stringify(formData),
                        token: token,
                    };
                    // console.log('FormData: ', data);
                    const queryParams = {
                        method: 'POST',
                        headers: {
                            'Content-Type':
                                'application/x-www-form-urlencoded; charset=UTF-8',
                        },
                        body: Object.entries(data)
                            .map(([k, v]) => {
                                return k + '=' + v;
                            })
                            .join('&'),
                    };
                    // console.log('Query Params: ', queryParams);
                    fetch('mailer/index.php', queryParams)
                        .then((res) => res.json())
                        .then((data) => {
                            if (data.success) {
                                // do something if successfull
                                result.success = true;
                            } else {
                                // do something if not
                                result.errors.push('send error (backend)');
                            }
                            resolve(result);
                        })

                        .finally(() => {
                            // handle resolve
                        });
                },

                (error) => {
                    // todo: оповестить юзера, что возникла ошибка!
                    result.errors.push(error);
                    // console.warn(error);
                    resolve(result);
                },
            );
        });
    }
}
